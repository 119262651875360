<section *ngIf="user && user.status >= Status.VOTER">
  <div *ngIf="!settings.isVotingOpen(user) || user.rankingsSubmitted?.includes(year) || settings.isPastVotingDeadline()"
       class="flex-col justify-center align-center">
    <mat-card class="flex-col">
      <h3 *ngIf="!settings.isVotingOpen(user)">
        The voting window for {{year}} is not yet open.
      </h3>
      <p *ngIf="!settings.isVotingOpen(user)">
        You will be notified by email when the window opens.
      </p>
      <h3 *ngIf="user.rankingsSubmitted?.includes(year) && !settings.isPastVotingDeadline()">
        Your rankings have been submitted!
      </h3>
      <p *ngIf="user.rankingsSubmitted?.includes(year) && !settings.isPastVotingDeadline()">
        You now also have access to <b>review the data that will be made public</b> once results are announced.
      </p>
      <p *ngIf="user.rankingsSubmitted?.includes(year) && !settings.isPastVotingDeadline()">
        If you want to make any changes before the {{settings.votingDeadlineString()}} deadline, you
        may reopen them below, but be sure to resubmit before the deadline or they won't be counted.
      </p>
      <h3 *ngIf="user.rankingsSubmitted?.includes(year) && settings.isPastVotingDeadline()">
        Your rankings were submitted!
      </h3>
      <p *ngIf="user.rankingsSubmitted?.includes(year) && settings.isPastVotingDeadline()">
        The voting window for {{year}} is closed, and your rankings are final.
      </p>
      <h3 *ngIf="!user.rankingsSubmitted?.includes(year) && settings.isPastVotingDeadline()">
        The voting deadline has passed.
      </h3>
      <p *ngIf="!user.rankingsSubmitted?.includes(year) && settings.isPastVotingDeadline()">
        You did not submit rankings this year. There's always next year!
      </p>
      <div class="flex-row flex-wrap gap-5">
        <a mat-raised-button color="secondary" routerLink="/">Back to Home</a>
        <a *ngIf="user.rankingsSubmitted?.includes(year) && !settings.isPastVotingDeadline()"
           mat-raised-button color="primary" (click)="reopenRankings()">Reopen Rankings</a>
        <a *ngIf="user.rankingsSubmitted?.includes(year) && !settings.isPastVotingDeadline()"
           mat-raised-button color="primary" routerLink="/review">Review Public Data</a>
      </div>
    </mat-card>
    <div *ngIf="user.rankingsSubmitted?.includes(year)" class="flex-row flex-wrap gap-5">
      <mat-card *ngIf="regularRanking" class="flex-col flex-default gap-5">
        <h3 class="flex-row justify-center align-center">
          Your <span *ngIf="settings.hasOnlineRoomCategory()">In-Person</span> Room Rankings
        </h3>
        <div></div>
        <div *ngFor="let roomId of regularRanking.rankedIds; let i = index">
          {{ i+1 }}. <app-simpleroom [room]="rankableRoomMap.get(roomId)"></app-simpleroom>
        </div>
        <h3 *ngIf="getUnrankedIds(regularRanking).length" class="flex-row justify-center align-center">
          Unranked <span *ngIf="settings.hasOnlineRoomCategory()">In-Person</span> Rooms
        </h3>
        <div *ngIf="getUnrankedIds(regularRanking).length"></div>
        <div *ngFor="let roomId of getUnrankedIds(regularRanking); let i = index">
          <span matTooltip="{{ getUnrankedReason(Category.TOP_ROOM, roomId) }}">{{ getUnrankedEmoji(Category.TOP_ROOM, roomId) }}</span>
          <app-simpleroom [room]="rankableRoomMap.get(roomId)"></app-simpleroom>
       </div>
      </mat-card>
      <mat-card *ngIf="settings.hasOnlineRoomCategory() && onlineRanking" class="flex-col flex-default gap-5">
        <h3 class="flex-row justify-center align-center">
          Your Online Room Rankings
        </h3>
        <div></div>
        <div *ngFor="let roomId of onlineRanking.rankedIds; let i = index">
          {{ i+1 }}. <app-simpleroom [room]="rankableRoomMap.get(roomId)"></app-simpleroom>
        </div>
        <h3 *ngIf="getUnrankedIds(onlineRanking).length" class="flex-row justify-center align-center">
          Unranked Online Rooms
        </h3>
        <div *ngIf="getUnrankedIds(onlineRanking).length"></div>
        <div *ngFor="let roomId of getUnrankedIds(onlineRanking); let i = index">
          <span matTooltip="{{ getUnrankedReason(Category.TOP_ONLINE_ROOM, roomId) }}">{{ getUnrankedEmoji(Category.TOP_ONLINE_ROOM, roomId) }}</span>
          <app-simpleroom [room]="rankableRoomMap.get(roomId)"></app-simpleroom>
        </div>
      </mat-card>
    </div>
  </div>
  <div *ngIf="settings.isVotingOpen(user) && !settings.isPastVotingDeadline() && !user.rankingsSubmitted?.includes(year)"
       class="flex-col justify-center align-stretch">
    <h3 class="flex-row justify-center align-center">
      {{ year }} TERPECA Rankings Form
    </h3>
    <div *ngIf="!hideRules" class="rules flex-col justify-center align-center">
      <b style="color: red">IMPORTANT: Read this first!</b>
      <ul>
        <li>
          <b>Rankings must be submitted by {{settings.votingDeadlineString()}}.</b>
        </li>
        <li>
          <b>This is an individual process.</b> Your rankings are your own, and they should be
          unique to you. Do not discuss your specific rankings with anyone else until you've submitted,
          and do not submit rankings on behalf of anyone but yourself.
        </li>
        <li>
          <b>You will be asked to update your bio for use on the website.</b> Please be sure to
          make this exactly how you intend for it to be. You may refer to other people that you
          typically play with in your bio, but please do not write it as if the one bio represents
          more than one person.
        </li>
        <li>
          <b>You will be asked to disclose any room with which you have any direct affiliation, past or
          present.</b> This includes owners, designers, employees, investors, consultants, etc.  You
          will not be permitted to rank any of those rooms.
        </li>
        <li>
          Your ranking data will auto-save, but <b>it will not be officially submitted until you hit the
          "Submit Rankings" button on the final confirmation page of the form.</b>
        </li>
      </ul>
      <button *ngIf="!confirmedRules" mat-raised-button color="primary" (click)="toggleRules()">Confirm</button>
      <button *ngIf="confirmedRules && !hideRules" mat-raised-button color="primary" (click)="toggleRules()">Hide Rules</button>
    </div>
    <div *ngIf="hideRules" class="flex-col justify-center align-center gap-5">
      <span>Need some guidance? Check out the <a href="https://www.youtube.com/watch?v=d3NALWbQyrk" target="_blank">How to Vote</a> video.</span>
      <button mat-raised-button color="primary" (click)="toggleRules()">Show Rules</button>
      <b class="flex-default" style="color: red">Rankings are due in {{settings.timeToVotingDeadlineString()}}.</b>
      <b class="flex-default" style="color: red" *ngIf="!user.rankingsSubmitted?.includes(year)">You have not yet submitted.</b>
      <span *ngIf="!confirmedRules || !readyToStart" class="flex-row justify-center align-center">
        Loading rooms... Please be patient, this can take a little while.
      </span>
    </div>
    <mat-horizontal-stepper #stepper *ngIf="confirmedRules && hideRules && readyToStart" [linear]=true labelPosition="bottom"
                            (selectionChange)="stepChanged($event)">
      <mat-step [completed]="firstFormGroup.valid && !firstFormGroup.dirty && furthestStepReached >= 0">
        <form [formGroup]="firstFormGroup" >
          <ng-template matStepLabel>
            Update Bio
          </ng-template>
          <h3>
            Step 1a. Update Your Bio
          </h3>
          <p class="description">
            Please update your biographical information as it will appear in the
            contribution section of the website.
          </p>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Real name</mat-label>
              <input type="text" matInput formControlName="realName" required>
              <mat-hint>Your real name (first and last)</mat-hint>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Total escape rooms played (as of 31 Oct)</mat-label>
              <input type="number" matInput formControlName="roomCount" required>
              <mat-hint>Estimate to the nearest round number <i>that you have definitely reached</i> if you don't have an exact count.
                DO include online escape rooms as defined below in this count.
                For clarification, see the <a href="/faq#what-counts" target="_blank">FAQ</a>.</mat-hint>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Total online escape rooms played (as of 31 Oct)</mat-label>
              <input type="number" matInput formControlName="virtualRoomCount" required [errorStateMatcher]="roomCountErrorMatcher">
              <mat-hint>This should include <b>only</b> hosted escape rooms played online, and these games should also
                be included in your total count above. For clarification, see the
                <a href="/faq#what-counts-online" target="_blank">FAQ</a>.</mat-hint>
              <mat-error *ngIf="firstFormGroup.errors?.invalidRoomCounts">
                Online room count cannot exceed total room count.
              </mat-error>
            </mat-form-field>
          </p>
          <p>
            <app-locationpicker [formGroup]="firstFormGroup" [forUser]=true></app-locationpicker>
          </p>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Bio</mat-label>
              <textarea matInput formControlName="bio" required
                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"></textarea>
              <mat-hint>
                A short, well-written bio to publicly represent you on the TERPECA website. For full instructions, see the
                <a href="/faq#bio" target="_blank">FAQ</a>.
              </mat-hint>
            </mat-form-field>
          </p>
          <h3>
            Step 1b. Indicate Your Horror Preference
          </h3>
          <p class="description">
            <span *ngIf="year === 2023">🆕</span> This year we are collecting data from our contributors to better understand
            the relationship between one's preferences regarding horror-themed games and how this impacts their rankings. Unlike
            the biological information above, your horror preference will NOT be published and will be visible only to TERPECA
            staff. This will only be used for research, and does not affect your rankings.
          </p>
          <app-horrorpreferencepicker [formGroup]="firstFormGroup"></app-horrorpreferencepicker>
          <h3>
            Step 1c. Indicate Your Interest in Presenting During Our Awards Show
          </h3>
          <p class="description">
            Each year, we ask select contributors to provide a short video to introduce our winning rooms at our live awards show in
            December. Please let us know if you'd be willing to film a short, 10-20 second video introducing one or more of the games
            you love. If there are any rooms you would be <i>especially</i> interested in presenting, please be sure to leave a quote
            for that room in the "Add Quotes" step later in this process and check the box after that quote that indicates your specific
            interest in presenting that room.
          </p>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Are you willing to submit a video introduction?</mat-label>
              <select matNativeControl formControlName="videoContributionInterest" required>
                <option></option>
                <option [ngValue]="true">Yes, count me in!</option>
                <option [ngValue]="false">No thanks, I'll pass.</option>
              </select>
            </mat-form-field>
          </p>
          <p>
            <button *ngIf="firstFormGroup.dirty" mat-raised-button color="primary" (click)="saveApplicationData()"
                    [disabled]="firstFormGroup.invalid">Save and Next</button>
            <button *ngIf="!firstFormGroup.dirty" mat-raised-button matStepperNext color="primary"
                    [disabled]="firstFormGroup.invalid">Next</button>
          </p>
        </form>
      </mat-step>
      <mat-step [completed]="affiliationsDisclosed() && !secondFormGroup.dirty && furthestStepReached >= 1">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>
            Disclose Affiliations
          </ng-template>
          <h3>
            Step 2. Disclose Your Affiliations
          </h3>
          <p class="description">
            <strong style="color: red">IMPORTANT: This is NOT the list of rooms you have played, please read carefully.</strong><br/>
            Before voting, you must first <strong>select every room in the following list with which you are affiliated.</strong>
            Owners, designers, employees, investors, consultants, or anyone else that may have a conflict of interest in voting for a
            given room <strong>must</strong> disclose that affiliation.
            <i>Note that every in-person and online room in our database is in this list, including those that you won't be able to rank in the next
            section.</i>
          </p>
          <p *ngIf="lastVoterYear()" class="info-box">
            ⭐ Since you ranked rooms in a previous year, any affiliated rooms you selected last time have been preselected for you.
          </p>
          <p *ngIf="!hasAffiliatedRooms()">
            <button mat-raised-button (click)="confirmAffiliations()" color="primary">
              I Have No Affiliations
            </button>
          </p>
          <div class="flex-row-reverse flex-wrap justify-space-between align-start">
            <div class="flex-col flex-default gap-10">
              <div class="title-section flex-col justify-center align-center">
                <div>
                  <strong>Affiliated Rooms</strong> ({{ numAffiliatedRooms() }})
                </div>
              </div>
              <p *ngIf="!hasAffiliatedRooms()">
                <button mat-raised-button (click)="confirmAffiliations()" color="primary">
                  I Have No Listed Affiliations
                </button>
              </p>
              <div *ngIf="hasAffiliatedRooms()">
                <div *ngFor="let room of affiliatedRooms()">
                  <app-simpleroom [room]=room></app-simpleroom>
                </div>
                <p>
                  <button mat-raised-button color="primary" (click)="confirmAffiliations()">
                    Submit Affiliations
                  </button>
                </p>
              </div>
            </div>
            <div class="flex-col flex-default gap-10">
              <div class="title-section flex-col justify-center align-center">
                <div>
                  <strong>All Rooms</strong> ({{ allRooms?.length || 0 }})
                </div>
              </div>
              <div class="search-and-filter-section flex-col justify-center align-center gap-5">
                <mat-radio-group class="flex-row flex-wrap justify-center align-center gap-25">
                  <div>sort by:</div>
                  <mat-radio-button value="1" color="primary" [checked]=true (click)="currentSortFn = roomCountrySort">country</mat-radio-button>
                  <mat-radio-button value="2" color="primary" (click)="currentSortFn = roomNameSort">name</mat-radio-button>
                  <mat-radio-button value="3" color="primary" (click)="currentSortFn = roomCompanySort">company</mat-radio-button>
                </mat-radio-group>
                <div class="flex-row justify-center align-center gap-10">
                  <div>country&nbsp;filter:</div>
                  <select class="select-filter" formControlName="countryFilter">
                    <option value="">All countries</option>
                    <option *ngFor="let country of allCountryList" [ngValue]="country">{{country}}</option>
                  </select>
                </div>
                <div class="flex-row justify-center align-center gap-10">
                  <div>search&nbsp;filter:</div>
                  <input class="search-box" type="string" matInput formControlName="searchText">
                </div>
              </div>
              <i *ngIf="maxResultsToShow()">
                Only the first {{ maxResultsToShow() }} rooms are shown. Please select a country or perform a search to see more.
              </i>
              <app-room *ngFor="let room of allRooms | roomFilter:secondFormGroup.value.countryFilter:'':secondFormGroup.value.searchText:false:currentSortFn:maxResultsToShow()"
                        [room]="room" [allowSelect]=true [allowEdit]=false [ranker]="this" [forDisclosure]=true
                        [selected]="isAffiliatedRoom(room.docId)" [selectLabel]="'Mark Affiliated'" [unselectLabel]="'Mark Unaffiliated'"
                        class="flex-row justify-start align-stretch"></app-room>
              <i *ngIf="maxResultsToShow()">
                There are more results to show. Please select a country or perform a search above to see more.
              </i>
            </div>
          </div>
          <p>
            <button mat-raised-button matStepperPrevious color="primary">Back</button>
            <button *ngIf="!affiliationsDisclosed() || secondFormGroup.dirty" mat-raised-button color="primary" (click)="confirmAffiliations()">Submit Affiliations</button>
            <button *ngIf="affiliationsDisclosed() && !secondFormGroup.dirty" mat-raised-button matStepperNext color="primary">Next</button>
          </p>
        </form>
      </mat-step>
      <mat-step [completed]="roomsSelected(Category.TOP_ROOM) && !thirdFormGroup.dirty && furthestStepReached >= 2">
        <form [formGroup]="thirdFormGroup">
          <ng-template matStepLabel>
            Select <span *ngIf="settings.hasOnlineRoomCategory()">In-Person</span> Rooms
          </ng-template>
          <h3>
            Step 3. Select the <span *ngIf="settings.hasOnlineRoomCategory()">In-Person</span> Rooms You've Played
          </h3>
          <p class="description">
            Now select <strong>every room in the following list that you have played at any time in the past</strong>, regardless of whether you liked it or not.
            You must have played the rooms you select here <strong>in person</strong>.
            <span *ngIf="settings.hasOnlineRoomCategory()">If you played them only online, you'll select those in a later step.</span>
            <i>Note that this list contains only this year's finalists for this category; not every nominee is listed as in Step 2.</i>
          </p>
          <p *ngIf="lastVoterYear()" class="info-box">
            ⭐ Since you ranked rooms in a previous year, any rooms you selected last time were preselected for you.
          </p>
          <p *ngIf="nominatedThisYear()" class="info-box">
            ⭐ Since you nominated rooms this year, any rooms you nominated were preselected for you.
          </p>
          <div class="flex-row-reverse flex-wrap justify-space-between align-start">
            <div class="flex-col flex-default gap-10">
              <div class="title-section flex-col justify-center align-center">
                <div>
                  <strong>Rooms Played</strong> ({{ numPlayedRooms(Category.TOP_ROOM) }})
                </div>
              </div>
              <p *ngIf="!hasPlayedRooms(Category.TOP_ROOM)">
                <button mat-raised-button (click)="confirmPlayedRooms(Category.TOP_ROOM)" color="primary">
                  I Have Not Played Any Finalist Rooms
                </button>
              </p>
              <div *ngIf="hasPlayedRooms(Category.TOP_ROOM)">
                <div *ngFor="let room of playedRooms(Category.TOP_ROOM)">
                  <app-simpleroom [room]=room></app-simpleroom>
                </div>
                <p>
                  <button mat-raised-button (click)="confirmPlayedRooms(Category.TOP_ROOM)" color="primary">
                    Submit Rooms Played
                  </button>
                </p>
              </div>
            </div>
            <div class="flex-col flex-default gap-10">
              <div class="title-section flex-col justify-center align-center">
                <div>
                  <strong>Finalist Rooms</strong> ({{ rankableRegularRooms?.length }})
                </div>
              </div>
              <div class="search-and-filter-section flex-col justify-center align-center gap-5">
                <mat-radio-group class="flex-row flex-wrap justify-center align-center gap-25">
                  <div>sort by:</div>
                  <mat-radio-button value="1" color="primary" [checked]=true (click)="currentSortFn = roomCountrySort">country</mat-radio-button>
                  <mat-radio-button value="2" color="primary" (click)="currentSortFn = roomNameSort">name</mat-radio-button>
                  <mat-radio-button value="3" color="primary" (click)="currentSortFn = roomCompanySort">company</mat-radio-button>
                </mat-radio-group>
                <div class="flex-row justify-center align-center gap-10">
                  <div>country&nbsp;filter:</div>
                  <select class="select-filter" formControlName="countryFilter">
                    <option value="">All countries</option>
                    <option *ngFor="let country of irlCountryList" [ngValue]="country">{{country}}</option>
                  </select>
                </div>
                <div class="flex-row justify-center align-center gap-10">
                  <div>search&nbsp;filter:</div>
                  <input class="search-box" type="string" matInput formControlName="searchText">
                </div>
              </div>
              <app-room *ngFor="let room of rankableRegularRooms | roomFilter:thirdFormGroup.value.countryFilter:'':thirdFormGroup.value.searchText:false:currentSortFn"
                        [room]="room" [allowSelect]=true [disableSelect]="isAffiliatedRoom(room.docId)" [allowEdit]=false [ranker]="this" [forDisclosure]=false
                        [selected]="isPlayedRoom(Category.TOP_ROOM, room.docId)" [selectLabel]="roomSelectLabel(room.docId)" [unselectLabel]="roomUnselectLabel(room.docId)"
                        class="flex-row justify-start align-stretch"></app-room>
            </div>
          </div>
          <p>
            <button mat-raised-button matStepperPrevious color="primary">Back</button>
            <button *ngIf="!roomsSelected(Category.TOP_ROOM) || thirdFormGroup.dirty" mat-raised-button color="primary" (click)="confirmPlayedRooms(Category.TOP_ROOM)">Submit Rooms Played</button>
            <button *ngIf="roomsSelected(Category.TOP_ROOM) && !thirdFormGroup.dirty" mat-raised-button matStepperNext color="primary">Next</button>
          </p>
        </form>
      </mat-step>
      <mat-step [completed]="!fourthFormGroup.dirty && furthestStepReached >= 3">
        <form [formGroup]="fourthFormGroup">
          <ng-template matStepLabel>
            Rank <span *ngIf="settings.hasOnlineRoomCategory()">In-Person</span> Rooms
          </ng-template>
          <h3>
            Step 4. Rank the <span *ngIf="settings.hasOnlineRoomCategory()">In-Person</span> Rooms You Selected
          </h3>
          <ng-container *ngIf="!hasPlayedRooms(Category.TOP_ROOM)">
            <p class="description">
              You have no <span *ngIf="settings.hasOnlineRoomCategory()">in-person</span> rooms to rank. Please proceed to the next step.
            </p>
          </ng-container>
          <ng-container *ngIf="hasPlayedRooms(Category.TOP_ROOM)">
            <p class="description">
              <strong>Carefully rank every room that you've selected from best to worst.</strong>
              Every spot in the rankings is important, so please take your time and do your best. Your progress
              will be saved, but nothing will be officially submitted until you finish Step 7 and hit the
              "Submit Rankings" button.
            </p>
            <p class="description">
              <strong>If you prefer not to rank a given room for any reason, hit the "Don't Rank" button for that room.</strong>
              You will be asked to provide a reason before the room is removed from your rankings.
            </p>
            <p *ngIf="lastVoterYear()" class="info-box">
              ⭐ Since you ranked rooms in a previous year, your rankings were seeded with the same order you
              submitted last time. Rooms you did not rank last year are labeled 🆕 and must be integrated into
              your ranked list.
            </p>
            <ng-container *ngIf="rankingNotes?.length">
              <p *ngFor="let note of rankingNotes" class="info-box">
                ⭐ {{ note }}
              </p>
            </ng-container>
            <div *ngIf="!readyToRank">
              Loading...
            </div>
            <div *ngIf="readyToRank">
              <div cdkDropList class="sortable-list" [cdkDropListAutoScrollStep]="10" (cdkDropListDropped)="drop($event)">
                @for (roomId of rankedRoomIds; track roomId) {
                  <div class="sortable-box" cdkDrag>
                    <div class="flex-row justify-start align-center gap-5">
                      <mat-icon cdkDragHandle>drag_handle</mat-icon>
                      <input
                        type="text" style="width: 3ex;"
                        inputmode="numeric" pattern="[0-9]{1,3}"
                        [value]="$index + 1"
                        (change)="rankNumberChange($index, $event.target)"
                        (focus)="$event.target.select()">
                      <app-simpleroom class="flex-default" [room]="rankableRoomMap.get(roomId)" [showNewLogo]=showNewLogo(roomId)></app-simpleroom>
                      <button type="button" (click)="toggleRoomRanked(roomId)" tabindex=-1>
                        Don't Rank
                      </button>
                    </div>
                  </div>
                }
              </div>
              <div *ngIf="unrankedRoomIds?.length" cdkDropList class="sortable-list" [cdkDropListAutoScrollStep]="10">
                <div class="sortable-box" style="background-color: lightgray;" *ngFor="let roomId of unrankedRoomIds">
                  <div class="flex-row justify-start align-center gap-5">
                    <span matTooltip="{{ getUnrankedReason(Category.TOP_ROOM, roomId) }}">{{ getUnrankedEmoji(Category.TOP_ROOM, roomId) }}</span>
                    <app-simpleroom class="flex-default" [room]="rankableRoomMap.get(roomId)" [showNewLogo]=showNewLogo(roomId)></app-simpleroom>
                    <button (click)="toggleRoomRanked(roomId)">
                      Rank
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <p>
            <button matStepperPrevious mat-raised-button color="primary">Back</button>
            <button *ngIf="fourthFormGroup.dirty" (click)="saveRankingData();" type="button" mat-raised-button color="primary">Save and Next</button>
            <button *ngIf="!fourthFormGroup.dirty" matStepperNext type="button" mat-raised-button color="primary">Next</button>
          </p>
        </form>
      </mat-step>
      <mat-step *ngIf="settings.hasOnlineRoomCategory()"
                [completed]="roomsSelected(Category.TOP_ONLINE_ROOM) && !fifthFormGroup.dirty && furthestStepReached >= 4">
        <form [formGroup]="fifthFormGroup">
          <ng-template matStepLabel>
            Select Online Rooms
          </ng-template>
          <h3>
            Step 5. Select the Online Rooms You've Played
          </h3>
          <p class="description">
            Now select <strong>every online room in the following list that you have played at any time in the past</strong>, regardless of whether you liked it or not.
            You must have played the rooms you select here <strong>online</strong>. If you played them only in person, you should have selected those in Step 3 instead.
            <i>Note that this list contains only this year's finalists for this category; not every nominee is listed as in Step 2.</i>
          </p>
          <p *ngIf="lastVoterYear() && year > 2020" class="info-box">
            ⭐ Since you ranked rooms in a previous year, any rooms you selected last time were preselected for you.
          </p>
          <p *ngIf="nominatedThisYear()" class="info-box">
            ⭐ Since you nominated rooms this year, any rooms you nominated were preselected for you.
          </p>
          <div class="flex-row-reverse flex-wrap justify-space-between align-start">
            <div class="flex-col flex-default gap-10">
              <div class="title-section flex-col justify-center align-center">
                <div>
                  <strong>Online Rooms Played</strong> ({{ numPlayedRooms(Category.TOP_ONLINE_ROOM) }})
                </div>
              </div>
              <p *ngIf="!hasPlayedRooms(Category.TOP_ONLINE_ROOM)">
                <button mat-raised-button (click)="confirmPlayedRooms(Category.TOP_ONLINE_ROOM)" color="primary">
                  I Have Not Played Any Finalist Online Rooms
                </button>
              </p>
              <div *ngIf="hasPlayedRooms(Category.TOP_ONLINE_ROOM)">
                <div *ngFor="let room of playedRooms(Category.TOP_ONLINE_ROOM)">
                  <app-simpleroom [room]=room></app-simpleroom>
                </div>
                <p>
                  <button mat-raised-button (click)="confirmPlayedRooms(Category.TOP_ONLINE_ROOM)" color="primary">
                    Submit Online Rooms Played
                  </button>
                </p>
              </div>
            </div>
            <div class="flex-col flex-default gap-10">
              <div class="title-section flex-col justify-center align-center">
                <div>
                  <strong>Finalist Online Rooms</strong> ({{ rankableOnlineRooms?.length }})
                </div>
              </div>
              <div class="search-and-filter-section flex-col justify-center align-center gap-5">
                <mat-radio-group class="flex-row flex-wrap justify-center align-center gap-25">
                  <div>sort by:</div>
                  <mat-radio-button value="1" color="primary" [checked]=true (click)="currentSortFn = roomCountrySort">country</mat-radio-button>
                  <mat-radio-button value="2" color="primary" (click)="currentSortFn = roomNameSort">name</mat-radio-button>
                  <mat-radio-button value="3" color="primary" (click)="currentSortFn = roomCompanySort">company</mat-radio-button>
                </mat-radio-group>
                <div class="flex-row justify-center align-center gap-10">
                  <div>country&nbsp;filter:</div>
                  <select class="select-filter" formControlName="countryFilter">
                    <option value="">All countries</option>
                    <option *ngFor="let country of onlineCountryList" [ngValue]="country">{{country}}</option>
                  </select>
                </div>
                <div class="flex-row justify-center align-center gap-10">
                  <div>search&nbsp;filter:</div>
                  <input class="search-box" type="string" matInput formControlName="searchText">
                </div>
              </div>
              <app-room *ngFor="let room of rankableOnlineRooms | roomFilter:fifthFormGroup.value.countryFilter:'':fifthFormGroup.value.searchText:false:currentSortFn"
                        [room]="room" [allowSelect]=true [disableSelect]="isAffiliatedRoom(room.docId)" [allowEdit]=false [ranker]="this" [forDisclosure]=false
                        [selected]="isPlayedRoom(Category.TOP_ONLINE_ROOM, room.docId)" [selectLabel]="roomSelectLabel(room.docId)" [unselectLabel]="roomUnselectLabel(room.docId)"
                        class="flex-row justify-start align-stretch"></app-room>
            </div>
          </div>
          <p>
            <button mat-raised-button matStepperPrevious color="primary">Back</button>
            <button *ngIf="!roomsSelected(Category.TOP_ONLINE_ROOM) || fifthFormGroup.dirty" mat-raised-button color="primary" (click)="confirmPlayedRooms(Category.TOP_ONLINE_ROOM)">Submit Online Rooms Played</button>
            <button *ngIf="roomsSelected(Category.TOP_ONLINE_ROOM) && !fifthFormGroup.dirty" mat-raised-button matStepperNext color="primary">Next</button>
          </p>
        </form>
      </mat-step>
      <mat-step *ngIf="settings.hasOnlineRoomCategory()" [completed]="!sixthFormGroup.dirty && furthestStepReached >= 5">
        <form [formGroup]="sixthFormGroup">
          <ng-template matStepLabel>
            Rank Online Rooms
          </ng-template>
          <h3>
            Step 6. Rank the Online Rooms You Selected
          </h3>
          <ng-container *ngIf="!hasPlayedRooms(Category.TOP_ONLINE_ROOM)">
            <p class="description">
              You have no online rooms to rank. Please proceed to the next step.
            </p>
          </ng-container>
          <ng-container *ngIf="hasPlayedRooms(Category.TOP_ONLINE_ROOM)">
            <p class="description">
              <strong>Carefully rank every online room that you've selected from best to worst.</strong>
              Every spot in the rankings is important, so please take your time and do your best. Your progress
              will be saved, but nothing will be officially submitted until you finish Step 7 and hit the
              "Submit Rankings" button.
            </p>
            <p class="description">
              <strong>If you prefer not to rank a given room for any reason, hit the "Don't Rank" button for that room.</strong>
              You will be asked to provide a reason before the room is removed from your rankings.
            </p>
            <p *ngIf="lastVoterYear() && year > 2020" class="info-box">
              ⭐ Since you ranked rooms in a previous year, your rankings were seeded with the same order you
              submitted last time. Rooms you did not rank last year are labeled 🆕 and must be integrated into
              your ranked list.
            </p>
            <ng-container *ngIf="rankingNotes?.length">
              <p *ngFor="let note of rankingNotes" class="info-box">
                ⭐ {{ note }}
              </p>
            </ng-container>
            <div *ngIf="!readyToRank">
              Loading...
            </div>
            <div *ngIf="readyToRank">
              <div cdkDropList class="sortable-list" (cdkDropListDropped)="drop($event)">
                <div class="sortable-box" *ngFor="let roomId of rankedRoomIds; let i = index" cdkDrag>
                  <div class="flex-row justify-start align-center gap-5">
                    <mat-icon cdkDragHandle>drag_handle</mat-icon>
                    <div>{{ i + 1 }}.</div>
                    <app-simpleroom class="flex-default" [room]="rankableRoomMap.get(roomId)" [showNewLogo]=showNewLogo(roomId)></app-simpleroom>
                    <button (click)="toggleRoomRanked(roomId)">
                      Don't Rank
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="unrankedRoomIds?.length" cdkDropList class="sortable-list">
                <div class="sortable-box" style="background-color: lightgray;" *ngFor="let roomId of unrankedRoomIds">
                  <div class="flex-row justify-start align-center gap-5">
                    <div>n/a.</div>
                    <app-simpleroom class="flex-default" [room]="rankableRoomMap.get(roomId)" [showNewLogo]=showNewLogo(roomId)></app-simpleroom>
                    <span matTooltip="{{ getUnrankedReason(Category.TOP_ONLINE_ROOM, roomId) }}">{{ getUnrankedEmoji(Category.TOP_ONLINE_ROOM, roomId) }}</span>
                    <button (click)="toggleRoomRanked(roomId)">
                      Rank
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <p>
            <button mat-raised-button matStepperPrevious color="primary">Back</button>
            <button *ngIf="sixthFormGroup.dirty" mat-raised-button color="primary" (click)="saveRankingData()">Save and Next</button>
            <button *ngIf="!sixthFormGroup.dirty" mat-raised-button matStepperNext color="primary">Next</button>
          </p>
        </form>
      </mat-step>
      <mat-step [completed]="furthestStepReached >= addQuotesStepIndex">
        <ng-template matStepLabel>
          Add Quotes
        </ng-template>
        <h3>
          Step {{ addQuotesStepIndex + 1 }}. Tell Us More About Your Favorite Rooms (Optional)
        </h3>
        <p class="description">
          Each year, we publish enthusiast quotes about each Top Room winner both on our public website at terpeca.com and in our live
          awards presentation. If you are interested in contributing to this effort, please provide an optional, spoiler-free quote,
          written in English, about any of your favorite rooms, and if that room wins a Top Room Award, we may choose to use your quote.
          If you left a quote about a room in a previous year, you do not need to leave the same quote again, as we already consider
          quotes left in previous years.
        </p>
        <p class="description">
          We will also ask select contributors to provide a short 10-20 second video to introduce our winning rooms at our live awards
          show in December. If there are any specific rooms you would be especially interested in presenting, please be sure to leave
          a new quote for that room and check the box that indicates your interest in presenting that room.
        </p>
        <div *ngIf="!quotableRooms()" class="info-box">
          No <span *ngIf="settings.hasOnlineRoomCategory()">in-person</span> rooms were ranked.
        </div>
        <div *ngIf="quotableRooms()">
          <app-quote *ngFor="let roomId of quotableRooms()" [room]="rankableRoomMap.get(roomId)"
                    [user]="user" [allowEdit]=true class="flex-row justify-start align-stretch"></app-quote>
        </div>
        <p>
          <button mat-raised-button matStepperPrevious color="primary">Back</button>
          <button mat-raised-button matStepperNext color="primary">Next</button>
        </p>
      </mat-step>
      <mat-step [completed]="furthestStepReached >= reviewAndSubmitStepIndex">
        <ng-template matStepLabel>
          Review and Submit
        </ng-template>
        <h3>
          Step {{ reviewAndSubmitStepIndex + 1 }}. Review and Submit Your Rankings
        </h3>
        <p class="description">
          Please review your rankings below, and if everything looks exactly as it should, hit "Submit Rankings" at the
          bottom to submit your rankings.
        </p>
        <p class="description">
          <i>Note that you will be able to reopen your rankings up until the voting deadline if you want to make any changes.</i>
        </p>
        <p *ngFor="let error of rankingErrors" class="warning-box">
          ❗ {{ error }}
        </p>
        <div class="flex-row flex-wrap gap-5">
          <mat-card *ngIf="regularRanking" class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">
              Your Pending <span *ngIf="settings.hasOnlineRoomCategory()">In-Person</span> Room Rankings
            </h3>
            <div></div>
            <div *ngIf="!regularRanking.rankedIds?.length">
              No <span *ngIf="settings.hasOnlineRoomCategory()">in-person</span> rooms were ranked.
            </div>
            <div *ngFor="let roomId of regularRanking.rankedIds; let i = index">
              {{ i+1 }}. <app-simpleroom [room]="rankableRoomMap.get(roomId)"></app-simpleroom>
            </div>
            <h3 *ngIf="getUnrankedIds(regularRanking).length" class="flex-row justify-center align-center">
              Unranked <span *ngIf="settings.hasOnlineRoomCategory()">In-Person</span> Rooms
            </h3>
            <div *ngIf="getUnrankedIds(regularRanking).length"></div>
            <div *ngFor="let roomId of getUnrankedIds(regularRanking); let i = index">
              <span matTooltip="{{ getUnrankedReason(Category.TOP_ROOM, roomId) }}">{{ getUnrankedEmoji(Category.TOP_ROOM, roomId) }}</span>
              <app-simpleroom [room]="rankableRoomMap.get(roomId)"></app-simpleroom>
            </div>
          </mat-card>
          <mat-card *ngIf="settings.hasOnlineRoomCategory() && onlineRanking" class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">
              Your Pending Online Room Rankings
            </h3>
            <div></div>
            <div *ngIf="!onlineRanking.rankedIds?.length">
              No online rooms were ranked.
            </div>
            <div *ngFor="let roomId of onlineRanking.rankedIds; let i = index">
              {{ i+1 }}. <app-simpleroom [room]="rankableRoomMap.get(roomId)"></app-simpleroom>
            </div>
            <h3 *ngIf="getUnrankedIds(onlineRanking).length" class="flex-row justify-center align-center">
              Unranked Online Rooms
            </h3>
            <div *ngIf="getUnrankedIds(onlineRanking).length"></div>
            <div *ngFor="let roomId of getUnrankedIds(onlineRanking); let i = index">
              <span matTooltip="{{ getUnrankedReason(Category.TOP_ONLINE_ROOM, roomId) }}">{{ getUnrankedEmoji(Category.TOP_ONLINE_ROOM, roomId) }}</span>
              <app-simpleroom [room]="rankableRoomMap.get(roomId)"></app-simpleroom>
            </div>
          </mat-card>
        </div>
        <p>
          <button mat-raised-button matStepperPrevious color="primary">Back</button>
          <button mat-raised-button color="primary" [disabled]="rankingErrors?.length > 0" (click)="confirmSubmitRankings()">Submit Rankings</button>
        </p>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</section>
