<mat-expansion-panel (opened)="panelOpened()">
  <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
    <mat-panel-title class="flex-row justify-space-between align-center">
      <div>
        <div><strong>{{ user.realName || user.displayName }}</strong></div>
        <div>{{ getLocationString(user) || 'TBD' }}</div>
      </div>
      <div class="flex-col justify-start align-end flex-noshrink">
        <div>
          👔 {{ rankingsComplete() ? user.affiliatedRoomIds?.length || 0 : tbdOrZero() }} &middot;
          🗒️ {{ user.rankingSubmitted ? (user.rankedRoomIds?.length || 0) + (user.rankedOnlineRoomIds?.length || 0) : tbdOrZero() }} &middot;
          🧩 {{ user.roomCount || tbdOrZero() }}
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="flex-col gap-10">
    <mat-divider></mat-divider>
    <div class="flex-col gap-5">
      <div class="terpeca-multiline"><i><b>Bio:</b></i> {{ user.bio }}</div>
    </div>
    <mat-divider></mat-divider>
    <div class="flex-col">
      <div>
        <i><b>🧩 Total Rooms Played: </b></i> {{ user.roomCount }}
        (<span matTooltip="In-person rooms">
          🔐 {{ user.roomCount - (user.virtualRoomCount || 0) }}
        </span> /
        <span matTooltip="Online rooms">
          💻 {{ user.virtualRoomCount || 0 }}
        </span>)
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="flex-col gap-5">
      <div>👔 <i><b>Affiliated Nominees:</b></i> {{ rankingsComplete() ? user.affiliatedRoomIds?.length || 0 : tbdOrZero() }}</div>
      <div *ngFor="let room of affiliatedRooms">
        <app-simpleroom [room]="room"></app-simpleroom>
      </div>
      <div *ngIf="(user.affiliatedRoomIds?.length || 0) > (affiliatedRooms?.length || 0)">
        <i>not showing {{ (user.affiliatedRoomIds?.length || 0) - (affiliatedRooms?.length || 0) }} previously nominated
           room{{ (user.affiliatedRoomIds?.length || 0) - (affiliatedRooms?.length || 0) > 1 ? 's' : '' }} that
           {{ (user.affiliatedRoomIds?.length || 0) - (affiliatedRooms?.length || 0) > 1 ? 'were' : 'was' }} not nominated this year</i>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="flex-col gap-5">
      <div>🗒️ <i><b>Finalists Played:</b></i> {{ user.rankingSubmitted ? (user.rankedRoomIds?.length || 0) + (user.rankedOnlineRoomIds?.length || 0) : tbdOrZero() }}
      (🔐 {{ user.rankingSubmitted ? (user.rankedRoomIds?.length || 0) : tbdOrZero() }} /
       💻 {{ user.rankingSubmitted ? (user.rankedOnlineRoomIds?.length || 0) : tbdOrZero() }}
      <span *ngIf="user.rankingSubmitted && (user.rankedRoomIds?.length > 1 || user.rankedOnlineRoomIds?.length > 1)">, sorted by location</span>)</div>
      <div *ngFor="let room of rankedRooms">
        <app-simpleroom [room]="room"></app-simpleroom>
      </div>
      <div *ngFor="let room of rankedOnlineRooms">
        <app-simpleroom [room]="room"></app-simpleroom>
      </div>
    </div>
  </div>
</mat-expansion-panel>
