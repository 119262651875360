import app from 'firebase/compat/app';

// The user's self-selected preference for horror.
export enum HorrorPreference {
  UNKNOWN,
  HATE,
  DISLIKE,
  NEUTRAL,
  LIKE,
  LOVE
}

export const allHorrorPreferences =
  (<(keyof typeof HorrorPreference)[]>Object.keys(HorrorPreference))
    .filter(key => isNaN(Number(key)) && HorrorPreference[key] > HorrorPreference.UNKNOWN)
    .map(key => HorrorPreference[key]);

export interface TerpecaUser {
  createTime: app.firestore.Timestamp;

  // This can only be changed manually in the database.
  isOwner: boolean;

  // These come from Firebase Auth.
  uid: string;
  email: string;
  displayName?: string;

  // These are provided by the user, and are visible to the public.
  appEmail?: string;
  realName?: string;
  roomCount?: number;
  virtualRoomCount?: number;
  city?: string;
  state?: string;
  country?: string;
  bio?: string;

  // These are provided by the user and are NOT visible to the public.
  slackUsername?: string;
  facebookUsername?: string;
  instagramUsername?: string;
  discordUsername?: string;
  bestContactMethod?: string;

  escapeTheReviewUserId?: string;
  escapeAllGrUserId?: string;
  escapeGameFrUserId?: string;
  escapeRoomersUserId?: string;
  escapeTalkNlUserId?: string;
  lockMeUserId?: string;
  mortyAppUsername?: string;
  theEscapersUserId?: string;

  // These are provided during the application/upgrade process, but are not otherwise visible.
  roomCountEvidence?: string;
  regionsPlayed?: string;
  onlinePresence?: string;
  otherExperience?: string;
  favoriteRooms?: string;
  affiliations?: string;
  prefsList?: string[];
  references?: string;

  // These are updated as things change.
  status: ApplicationStatus;
  hasResultsAccess?: boolean;
  disabled?: boolean;
  shadowbanned?: boolean;
  upgradeRequested?: boolean;
  vouchList?: string[];
  upgradeVouchList?: string[];
  videoContributionInterest?: boolean;
  horrorPreference?: HorrorPreference;

  applicationDenied?: number[];
  nominationRulesConfirmed?: number[];
  nominationsSubmitted?: number[];
  rankingRulesConfirmed?: number[];
  rankingsSubmitted?: number[];
  auditLogEntry?: UserAuditLogEntry;
  auditLogHistory?: UserAuditLogEntry[];

  // Unique IP addresses, pulled from audit log.
  ipAddresses?: string[];

  // Rooms with a disclosed affiliation.
  affiliatedRoomIds?: string[];

  // Countries served by ambassador
  ambassadorCountries?: string[];
  notifyOnNewApplicant?: boolean;
  notifyOnNewRoom?: boolean;

  // Allows dynamic property access.
  [key: string]: any;
}

export interface TerpecaUserDisclosure {
  // updated from TerpecaUser
  userId: string;  // from uid
  year: number;
  status: ApplicationStatus;
  realName?: string;
  roomCount?: number;
  virtualRoomCount?: number;
  city?: string;
  state?: string;
  country?: string;
  bio?: string;
  isContributor?: boolean;
  affiliatedRoomIds?: string[];
  rankingSubmitted?: boolean;

  // updated from TerpecaRanking
  // as of 2024, this includes played but unranked rooms
  rankedRoomIds?: string[];
  rankedOnlineRoomIds?: string[];

  // Allows dynamic property access.
  [key: string]: any;
}

export function getIpAddressesFromAuditLog(user: TerpecaUser) {
  const ips: string[] = [];
  if (user.auditLogEntry && user.auditLogEntry.uid === user.uid && user.auditLogEntry.ipAddress) {
    ips.push(user.auditLogEntry.ipAddress);
  }
  for (const auditLogEntry of user.auditLogHistory?.slice().reverse() || []) {
    if (auditLogEntry.uid === user.uid && auditLogEntry.ipAddress && !ips.includes(auditLogEntry.ipAddress)) {
      ips.push(auditLogEntry.ipAddress);
    }
  }
  return ips;
}

export enum ApplicationStatus {
  NONE = 0,
  PENDING,
  DENIED,
  VOTER,
  NOMINATOR,
  REVIEWER,
  APPROVER
}

export function getStatusString(status: ApplicationStatus): string {
  switch (status) {
    case ApplicationStatus.NONE:
      return 'unsubmitted';
    case ApplicationStatus.PENDING:
      return 'pending';
    case ApplicationStatus.DENIED:
      return 'denied';
    case ApplicationStatus.VOTER:
      return 'voter';
    case ApplicationStatus.NOMINATOR:
      return 'nominator';
    case ApplicationStatus.REVIEWER:
      return 'reviewer';
    case ApplicationStatus.APPROVER:
      return 'approver';
  }
}

export enum ApplicationResubmitReason {
  ONE_PERSON_ONLY,
  INVALID_NAME,
  INVALID_CITY,
  INVALID_FACEBOOK,
  INSUFFICIENT_BIO,
  INSUFFICIENT_EXPERIENCE_INFO,
  CUSTOM,
  INSUFFICIENT_IDENTITY_INFO,
  INSUFFICIENT_CONFLICT_DISCLOSURE,
  INSUFFICIENT_GAME_COUNT
}

export interface ApplicationResubmitRequestPayload {
  status: ApplicationStatus;
  reasons: ApplicationResubmitReason[];
  customInstructions?: string;
  ccEmail?: string;
}

export function getResubmitReasonString(reason: ApplicationResubmitReason): string {
  switch (reason) {
    case ApplicationResubmitReason.INSUFFICIENT_IDENTITY_INFO:
      return 'identity';
    case ApplicationResubmitReason.INSUFFICIENT_GAME_COUNT:
      return 'count';
    case ApplicationResubmitReason.INSUFFICIENT_EXPERIENCE_INFO:
      return 'evidence';
    case ApplicationResubmitReason.INSUFFICIENT_CONFLICT_DISCLOSURE:
      return 'disclosure';
    case ApplicationResubmitReason.ONE_PERSON_ONLY:
      return 'group';
    case ApplicationResubmitReason.INVALID_NAME:
      return 'name';
    case ApplicationResubmitReason.INVALID_CITY:
      return 'city';
    case ApplicationResubmitReason.INVALID_FACEBOOK:
      return 'facebook';
    case ApplicationResubmitReason.INSUFFICIENT_BIO:
      return 'bio';
    case ApplicationResubmitReason.CUSTOM:
      return 'custom';
  }
}

export interface UserAuditLogEntry {
  uid: string;
  name: string;
  entryType: UserAuditLogEntryType;
  payload: any;
  timestamp: app.firestore.Timestamp;
  ipAddress?: string;
}

export enum UserAuditLogEntryType {
  APPLICATION_STATUS_CHANGED,
  NOMINATIONS_SUBMITTED,
  RANKINGS_SUBMITTED,
  VOUCH_ADDED,
  VOUCH_REMOVED,
  RANKINGS_REOPENED,
  UPGRADE_REQUESTED,
  UPGRADE_DENIED,
  NOMINATIONS_REOPENED,
  UPGRADE_VOUCH_ADDED,
  UPGRADE_VOUCH_REMOVED,
  REVIEW_NOTE_ADDED,
  SHADOW_BAN_ON,
  SHADOW_BAN_OFF,
  NOMINATIONS_REVOKED,
  RANKINGS_REVOKED
}

export function getUserAuditLogString(entry: UserAuditLogEntry | undefined) {
  let description = '';
  if (!entry) {
    return description;
  }
  switch (entry.entryType) {
    case UserAuditLogEntryType.APPLICATION_STATUS_CHANGED:
      if (typeof entry.payload === 'number') {
        description = `status changed to ${getStatusString(<ApplicationStatus>entry.payload)}`;
      } else {
        description = `status changed to ${getStatusString(<ApplicationStatus>entry.payload.status)}`;
        if (entry.payload.reasons) {
          description += ' (' + <string>entry.payload.reasons.map(
            (reason: ApplicationResubmitReason) => getResubmitReasonString(reason)
          ).join(', ') + ')';
        }
      }
      break;
    case UserAuditLogEntryType.VOUCH_ADDED:
      description = `vouch added` + (entry.payload ? `: ${entry.payload}` : '');
      break;
    case UserAuditLogEntryType.VOUCH_REMOVED:
      description = `vouch removed` + (entry.payload ? `: ${entry.payload}` : '');
      break;
    case UserAuditLogEntryType.UPGRADE_VOUCH_ADDED:
      description = `upgrade vouch added` + (entry.payload ? `: ${entry.payload}` : '');
      break;
    case UserAuditLogEntryType.UPGRADE_VOUCH_REMOVED:
      description = `upgrade vouch removed` + (entry.payload ? `: ${entry.payload}` : '');
      break;
    case UserAuditLogEntryType.NOMINATIONS_SUBMITTED:
      description = `${entry.payload} nominations submitted`;
      break;
    case UserAuditLogEntryType.NOMINATIONS_REOPENED:
      description = `${entry.payload} nominations reopened`;
      break;
    case UserAuditLogEntryType.RANKINGS_SUBMITTED:
      description = `${entry.payload} rankings submitted`;
      break;
    case UserAuditLogEntryType.RANKINGS_REOPENED:
      description = `${entry.payload} rankings reopened`;
      break;
    case UserAuditLogEntryType.UPGRADE_REQUESTED:
      description = `upgrade requested`;
      break;
    case UserAuditLogEntryType.UPGRADE_DENIED:
      description = `upgrade declined`;
      if (entry.payload?.reasons) {
        description += ' (' + <string>entry.payload.reasons.map(
          (reason: ApplicationResubmitReason) => getResubmitReasonString(reason)
        ).join(', ') + ')';
      }
      break;
    case UserAuditLogEntryType.REVIEW_NOTE_ADDED:
      description = `review note: ${entry.payload}`;
      break;
    case UserAuditLogEntryType.SHADOW_BAN_ON:
      description = `shadow ban on` + (entry.payload ? `: ${entry.payload}` : '');
      break;
    case UserAuditLogEntryType.SHADOW_BAN_OFF:
      description = `shadow ban off` + (entry.payload ? `: ${entry.payload}` : '');
      break;
    case UserAuditLogEntryType.NOMINATIONS_REVOKED:
      description = `${entry.payload} nominations revoked`;
      break;
    case UserAuditLogEntryType.RANKINGS_REVOKED:
      description = `${entry.payload} rankings revoked`;
      break;
  }
  return `${description} - ${entry.name} ${entry.timestamp.toDate().toLocaleString()}${entry.ipAddress ? ' ' + entry.ipAddress : ''}`;
}
