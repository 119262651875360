import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription, timer } from 'rxjs';
import { debounce, pairwise, startWith } from 'rxjs/operators';

import { TerpecaRankedEntity } from 'src/app/models/results.model';
import { HorrorLevel, TerpecaCategory, TerpecaRoom, allHorrorLevels } from 'src/app/models/room.model';
import { SettingsService } from 'src/app/services/settings.service';
import {
    RoomFilter, getCountriesForRoom, getLanguages, getLocationString, getStateName, getStatesForRoom, isFinalist, isNominee,
    isPermanentlyIneligible, isWinner, languageName, latestNomineeYear, numNominations
} from 'src/app/utils/misc.utils';
import { compareEntitiesByLocation } from 'src/app/utils/sorting.utils';

@Component({
  selector: 'app-roomfinder',
  templateUrl: './roomfinder.component.html',
  styleUrl: './roomfinder.component.css'
})
export class RoomFinderComponent implements OnInit, OnDestroy {
  ALL_YEARS = -1;
  allCountries = [];
  allStates = [];
  allProvinces = [];
  allHorrorLevels = allHorrorLevels;
  allLanguages = [];
  isWinner = isWinner;
  isFinalist = isFinalist;
  isNominee = isNominee;
  isClosed = isPermanentlyIneligible;
  languageName = languageName;
  locationName = getLocationString;
  getStateName = getStateName;
  loadingInProgress = false;
  dataSource = null;
  hasUnrankedRooms = false;
  hasDuplicateRanks = false;
  lastYearSort = 0;
  numRoomsShowing = 0;
  numRoomsLoaded = 0;
  allRooms: TerpecaRoom[] = [];
  roomFilter: RoomFilter = new RoomFilter();
  languageSet = new Set<string>();
  countrySet = new Set<string>();
  stateSet = new Set<string>();
  provinceSet = new Set<string>();
  roomColumns: string[] = ['rank', 'room', 'company', 'city', 'country', 'tags', 'lastRank', 'bestRank'];

  formGroup: UntypedFormGroup;
  subscription: Subscription;

  constructor(public settings: SettingsService, private db: AngularFirestore, private router: Router, private route: ActivatedRoute) { }

  // eslint-disable-next-line @angular-eslint/no-async-lifecycle-method
  async ngOnInit() {
    const defaultYear = this.allYears()[0];
    const queryParamMap = this.route.snapshot.queryParamMap;
    this.formGroup = new UntypedFormGroup({
      year: new UntypedFormControl(Number(queryParamMap.get('year') || defaultYear)),
      countryFilter: new UntypedFormControl(queryParamMap.get('country') || ''),
      stateFilter: new UntypedFormControl(queryParamMap.get('state') || ''),
      horrorLevelFilter: new UntypedFormControl(JSON.parse(queryParamMap.get('horror')) || allHorrorLevels),
      languageFilter: new UntypedFormControl(queryParamMap.get('language') || ''),
      searchText: new UntypedFormControl(queryParamMap.get('query') || '')
    });
    this.formGroup.disable();
    await this.loadRooms();
    await this.updateDataSource();
    this.formGroup.enable();
    this.formGroup.markAsDirty();
    this.subscription = this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value),
      pairwise(),
      debounce(([previous, current]) => (
        // eslint-disable-next-line no-constant-binary-expression
        (this.loadingInProgress = true) &&
        previous.searchText !== current.searchText ? timer(500) : timer(0)))
    ).subscribe(async () => {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          year: this.formGroup.value.year || null,
          country: this.formGroup.value.countryFilter || null,
          state: this.formGroup.value.stateFilter || null,
          horror: this.formGroup.value.horrorLevelFilter.length === allHorrorLevels.length ? null :
            JSON.stringify(this.formGroup.value.horrorLevelFilter),
          language: this.formGroup.value.languageFilter || null,
          query: this.formGroup.value.searchText || null
        },
        queryParamsHandling: 'merge'
      });
      this.updateDataSource();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async loadRooms() {
    this.loadingInProgress = true;
    await this.db.collection<TerpecaRoom>('rooms').ref
    .where('category', '==', TerpecaCategory.TOP_ROOM).get()
    .then((snapshot: QuerySnapshot<TerpecaRoom>) => {
      for (const doc of snapshot.docs) {
        const room: TerpecaRoom = doc.data();
        if (!room.isNominee?.length) {
          continue;
        }
        room.docId = doc.id;
        this.allRooms.push(room);
        const countries = getCountriesForRoom(room);
        for (const country of countries) {
          this.countrySet.add(country);
          if (country === 'Canada') {
            const states = getStatesForRoom(room, country);
            for (const state of states) {
              this.provinceSet.add(state);
            }
          } else if (country === 'United States') {
            const states = getStatesForRoom(room, country);
            for (const state of states) {
              this.stateSet.add(state);
            }
          }
        }
        const languages = getLanguages(room);
        for (const language of languages) {
          if (language !== 'en') {
            this.languageSet.add(language);
          }
        }
      }
      this.allRooms.sort(this.compareByRank());
      this.lastYearSort = this.formGroup.value.year;
      this.numRoomsLoaded = this.allRooms.length;
      this.allCountries = Array.from(this.countrySet.values()).sort();
      this.allStates = Array.from(this.stateSet.values()).sort((a, b) => getStateName(a).localeCompare(getStateName(b)));
      this.allProvinces = Array.from(this.provinceSet.values()).sort((a, b) => getStateName(a).localeCompare(getStateName(b)));
      this.allLanguages = Array.from(this.languageSet.values()).sort((a, b) => languageName(a).localeCompare(languageName(b)));
    });
    this.loadingInProgress = false;
  }

  async updateDataSource() {
    if (this.lastYearSort !== this.formGroup.value.year) {
      this.lastYearSort = this.formGroup.value.year;
    }
    let filteredSource = this.allRooms.filter(r => this.matchesFilters(r));
    filteredSource = this.roomFilter.transform(
      filteredSource, this.formGroup.value.countryFilter, this.formGroup.value.stateFilter, this.formGroup.value.searchText, false,
      this.compareByRank());
    this.numRoomsShowing = filteredSource.length;
    this.updateAnomalyTags(filteredSource);
    this.dataSource = new MatTableDataSource(filteredSource);
    this.loadingInProgress = false;
  }

  updateAnomalyTags(rooms: TerpecaRoom[]) {
    let hasDuplicateRanks = false;
    let hasUnrankedRooms = false;
    let lastRank = 0;
    for (const room of rooms) {
      const year = this.yearToShow(room);
      if (isFinalist(room, year) && room.resultsData && room.resultsData[year]) {
        const entity: TerpecaRankedEntity = room.resultsData[year];
        if (entity.unranked) {
          hasUnrankedRooms = true;
        } else if (entity.rank) {
          if (entity.rank === lastRank) {
            hasDuplicateRanks = true;
          }
          lastRank = entity.rank;
        }
      }
    }
    this.hasDuplicateRanks = hasDuplicateRanks;
    this.hasUnrankedRooms = hasUnrankedRooms;
  }

  showingAllYears() {
    return this.formGroup.value.year === this.ALL_YEARS;
  }

  yearToShow(room: TerpecaRoom) {
    return this.showingAllYears() ? latestNomineeYear(room) : this.formGroup.value.year;
  }

  yearForRowHighlight(room: TerpecaRoom) {
    return this.showingAllYears() ? latestNomineeYear(room) : this.formGroup.value.year;
  }

  bestYear(room: TerpecaRoom) {
    let bestYear = 0;
    if (room.isFinalist?.length) {
      let bestRank = Number.MAX_SAFE_INTEGER;
      for (const finalistYear of room.isFinalist) {
        if (this.canShowRanks(finalistYear) && room.resultsData && room.resultsData[finalistYear]) {
          const thisRank = room.resultsData[finalistYear].rank;
          if (!bestRank || (thisRank && thisRank < bestRank) || (bestYear && thisRank === bestRank && finalistYear > bestYear)) {
            bestYear = finalistYear;
            bestRank = thisRank;
          }
        }
        if (!bestYear) {
          bestYear = finalistYear;
        }
      }
    } else if (room.isNominee?.length) {
      let bestNomCount = 0;
      for (const nomineeYear of room.isNominee) {
        if (this.canShowRanks(nomineeYear)) {
          const thisNumCount = numNominations(room, nomineeYear);
          if (thisNumCount > bestNomCount || (thisNumCount === bestNomCount && nomineeYear > bestYear)) {
            bestYear = nomineeYear;
            bestNomCount = thisNumCount;
          }
        }
        if (!bestYear) {
          bestYear = nomineeYear;
        }
      }
    }
    return bestYear;
  }

  matchesFilters(room: TerpecaRoom) {
    if (this.formGroup.value.year !== this.ALL_YEARS && !isNominee(room, this.formGroup.value.year)) {
      return false;
    }
    if (!this.matchesHorrorLevelFilter(this.formGroup.value.horrorLevelFilter, room)) {
      return false;
    }
    if (!this.matchesLanguageFilter(this.formGroup.value.languageFilter, room)) {
      return false;
    }
    return true;
  }

  matchesHorrorLevelFilter(filter: HorrorLevel[], room: TerpecaRoom) {
    return filter?.length === allHorrorLevels.length || filter?.includes(room.horrorLevel || HorrorLevel.UNKNOWN);
  }

  matchesLanguageFilter(filter: string, room: TerpecaRoom) {
    if (!filter) {
      return true;
    }
    return getLanguages(room).includes(filter);
  }

  horrorLevel(room: TerpecaRoom) {
    return room.horrorLevel || HorrorLevel.UNKNOWN;
  }

  languages(room: TerpecaRoom) {
    return getLanguages(room);
  }

  compareByRank() {
    return (a: TerpecaRoom, b: TerpecaRoom) => {
      const aYear = this.yearToShow(a);
      const bYear = this.yearToShow(b);
      if (aYear > bYear) {
        return -1;
      }
      if (aYear < bYear) {
        return 1;
      }
      let year = aYear;
      if (!this.canShowRanks(year)) {
        if (isFinalist(a, year) && !isFinalist(b, year)) {
          return -1;
        }
        if (!isFinalist(a, year) && isFinalist(b, year)) {
          return 1;
        }
        // When we can't show ranks, we use the previous year to rank instead.
        year -= 1;
      }
      if (isFinalist(a, year) && isFinalist(b, year)) {
        if (this.canShowRanks(year)) {
          if (!a.resultsData || !a.resultsData[year]) {
            return -1;
          }
          if (!b.resultsData || !b.resultsData[year]) {
            return 1;
          }
          if (b.resultsData[year].score !== a.resultsData[year].score) {
            return b.resultsData[year].score - a.resultsData[year].score;
          }
          return a.resultsData[year].rank - b.resultsData[year].rank;
        }
        return compareEntitiesByLocation(a, b);
      }
      if (isFinalist(a, year)) {
        return -1;
      }
      if (isFinalist(b, year)) {
        return 1;
      }
      if (this.canShowRanks(year)) {
        const aNoms = numNominations(a, year);
        const bNoms = numNominations(b, year);
        if (aNoms !== bNoms) {
          return bNoms - aNoms;
        }
      }
      return compareEntitiesByLocation(a, b);
    };
  }

  canShowRanks(year) {
    return this.settings.isFinishedYear(year);
  }

  allYears() {
    if (this.settings.isVotingOpen()) {
      return this.settings.allYears.slice().reverse();
    }
    return this.settings.finishedYears().slice().reverse();
  }
}
