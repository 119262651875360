import { Pipe, PipeTransform } from '@angular/core';

import { languagesAll } from 'countries-list';

import { environment } from 'src/environments/environment';

import { TerpecaNomination } from '../models/nomination.model';
import { TerpecaCategory, TerpecaRoom } from '../models/room.model';
import { TerpecaUser, TerpecaUserDisclosure } from '../models/user.model';

const MAX_REGULAR_ROOM_NOMINATIONS = 10;  // lowered from 15 to 11 in 2023, then from 11 to 10 in 2024
const MAX_ONLINE_ROOM_NOMINATIONS = 0;    // lowered from 10 to 0 in 2022
const MIN_YEAR = 2018;

export function isLocalhost() {
  return window.location.hostname === 'localhost';
}

export function isRoomFinderApp() {
  return window.location.hostname === environment.roomFinderHostname;
}

export function isRoomFinderPage() {
  return isRoomFinderApp() || window.location.pathname === '/finder';
}

export function voterPortalUrl() {
  return isLocalhost() ? '/' : `https://${environment.voterPortalHostname}`;
}

export function roomFinderUrl() {
  return isLocalhost() ? '/finder' : `https://${environment.roomFinderHostname}`;
}

export function getLocationString(entity: any, excludeCountry?: boolean): string {
  if (!entity) {
    return '';
  }
  if (!entity.city && entity.homeCity) {
    return entity.homeCity;
  }
  let location = entity.city || '';
  if (entity.state) {
    location += `, ${entity.state}`;
  }
  if (!excludeCountry && entity.country) {
    if (entity.country === 'United Kingdom') {
      location += `, UK`;
    } else if (entity.country === 'United States') {
      location += `, USA`;
    } else {
      location += `, ${entity.country}`;
    }
  }
  return location;
}

// TODO: Unify this with the code in LanguagePickerComponent
export function isEnglishSpeaking(country: string, state?: string): boolean {
  if (country === 'Canada') {
    return state !== 'QC';
  }
  return country && ['United States', 'United Kingdom', 'Australia', 'New Zealand'].includes(country);
}

export function getLanguages(room?: TerpecaRoom | TerpecaNomination) {
  if (room?.languages?.length) {
    return room.languages;
  }
  return isEnglishSpeaking(room?.country, room?.state) ? ['en'] : [];
}

export function languageName(alpha2: string): string {
  const language = languagesAll[alpha2];
  return language ? language.name : '';
}

export function getCommonLocation(rooms: TerpecaRoom[]) {
  const countries: string[] = [];
  let city: string;
  let state: string;
  let seenFirst = false;
  for (const room of rooms) {
    let c = room.country;
    if (c === 'United Kingdom') {
      c = 'UK';
    }
    if (c === 'United States') {
      c = 'USA';
    }
    if (!seenFirst) {
      city = room.city;
      state = room.state || null;
      countries.push(c);
    } else {
      if (!countries.includes(c)) {
        countries.push(c);
        city = null;
        state = null;
      } else if (city !== room.city || state !== (room.state || null)) {
        city = null;
        state = null;
      }
    }
    seenFirst = true;
  }
  if (countries.length === 0) {
    return 'n/a';
  }
  if (countries.length > 1) {
    countries.sort();
    return countries.join(' / ');
  }
  if (city === null) {
    return countries[0];
  }
  if (state !== null && state.length > 0) {
    return `${city}, ${state}, ${countries[0]}`;
  }
  return `${city}, ${countries[0]}`;
}

export function getStateName(state2: string) {
  switch (state2) {
    case 'AL': return 'Alabama';
    case 'AK': return 'Alaska';
    case 'AZ': return 'Arizona';
    case 'AR': return 'Arkansas';
    case 'CA': return 'California';
    case 'CO': return 'Colorado';
    case 'CT': return 'Connecticut';
    case 'DE': return 'Delaware';
    case 'DC': return 'District Of Columbia';
    case 'FL': return 'Florida';
    case 'GA': return 'Georgia';
    case 'HI': return 'Hawaii';
    case 'ID': return 'Idaho';
    case 'IL': return 'Illinois';
    case 'IN': return 'Indiana';
    case 'IA': return 'Iowa';
    case 'KS': return 'Kansas';
    case 'KY': return 'Kentucky';
    case 'LA': return 'Louisiana';
    case 'ME': return 'Maine';
    case 'MD': return 'Maryland';
    case 'MA': return 'Massachusetts';
    case 'MI': return 'Michigan';
    case 'MN': return 'Minnesota';
    case 'MS': return 'Mississippi';
    case 'MO': return 'Missouri';
    case 'MT': return 'Montana';
    case 'NE': return 'Nebraska';
    case 'NV': return 'Nevada';
    case 'NH': return 'New Hampshire';
    case 'NJ': return 'New Jersey';
    case 'NM': return 'New Mexico';
    case 'NY': return 'New York';
    case 'NC': return 'North Carolina';
    case 'ND': return 'North Dakota';
    case 'OH': return 'Ohio';
    case 'OK': return 'Oklahoma';
    case 'OR': return 'Oregon';
    case 'PA': return 'Pennsylvania';
    case 'RI': return 'Rhode Island';
    case 'SC': return 'South Carolina';
    case 'SD': return 'South Dakota';
    case 'TN': return 'Tennessee';
    case 'TX': return 'Texas';
    case 'UT': return 'Utah';
    case 'VT': return 'Vermont';
    case 'VA': return 'Virginia';
    case 'WA': return 'Washington';
    case 'WV': return 'West Virginia';
    case 'WI': return 'Wisconsin';
    case 'WY': return 'Wyoming';
    case 'AS': return 'American Samoa';
    case 'GU': return 'Guam';
    case 'MP': return 'Northern Mariana Islands';
    case 'PR': return 'Puerto Rico';
    case 'UM': return 'United States Minor Outlying Islands';
    case 'VI': return 'Virgin Islands';
    case 'AB': return 'Alberta';
    case 'BC': return 'British Columbia';
    case 'MB': return 'Manitoba';
    case 'NB': return 'New Brunswick';
    case 'NL': return 'Newfoundland and Labrador';
    case 'NT': return 'Northwest Territories';
    case 'NS': return 'Nova Scotia';
    case 'NU': return 'Nunavut';
    case 'ON': return 'Ontario';
    case 'PE': return 'Prince Edward Island';
    case 'QC': return 'Quebec';
    case 'SK': return 'Saskatchewan';
    case 'YT': return 'Yukon';
  }
  return state2;
}

export function getEmails(rooms: TerpecaRoom[]) {
  const emails: string[] = [];
  for (const room of rooms) {
    if (room.email && !emails.includes(room.email)) {
      emails.push(room.email);
    }
  }
  emails.sort();
  return emails;
}

export function hasPastContributions(user: TerpecaUser) {
  return getPastYearsContributed(user).length > 0;
}

export function getPastYearsContributed(user: TerpecaUser) {
  const years: number[] = [];
  for (let y = MIN_YEAR; y < environment.currentAwardYear; ++y) {
    if (user?.nominationsSubmitted?.includes(y) || user?.rankingsSubmitted?.includes(y)) {
      years.push(y);
    }
  }
  return years;
}

export function lastNominatorYear(user: TerpecaUser, priorToYear?: number) {
  for (let y = priorToYear - 1; y >= MIN_YEAR; --y) {
    if (user.nominationsSubmitted?.includes(y)) {
      return y;
    }
  }
  return false;
}

export function lastVoterYear(user: TerpecaUser, priorToYear?: number) {
  for (let y = priorToYear - 1; y >= MIN_YEAR; --y) {
    if (user.rankingsSubmitted?.includes(y)) {
      return y;
    }
  }
  return false;
}

export function maxRegularNoms(user: TerpecaUser) {
  const totalRoomsPlayed = user.roomCount || 0;
  const totalOnlineRoomsPlayed = user.virtualRoomCount || 0;
  const totalRegularRoomsPlayed = Math.max(0, totalRoomsPlayed - totalOnlineRoomsPlayed);
  return Math.min(Math.floor(0.1 * totalRegularRoomsPlayed), MAX_REGULAR_ROOM_NOMINATIONS);
}

export function maxOnlineNoms(user: TerpecaUser) {
  const totalOnlineRoomsPlayed = user.virtualRoomCount || 0;
  return Math.min(Math.floor(0.1 * totalOnlineRoomsPlayed), MAX_ONLINE_ROOM_NOMINATIONS);
}

export function getNominationCount(room: TerpecaRoom, year?: number) {
  if (room && room.nominations) {
    if (!year) {
      return room.nominations.length;
    }
    let count = 0;
    for (const nomId of room.nominations || []) {
      if (nomId.year === year) {
        count++;
      }
    }
    return count;
  }
  return 0;
}

export function isValidNomination(nomination: TerpecaNomination) {
  // These fields need to be set no matter what.
  if (
      !nomination ||
      !nomination.year ||
      !Object.values(TerpecaCategory).includes(nomination.category) ||
      !nomination.userId
  ) {
    return false;
  }

  // Basic room data needs to be set for anything pre-2020 or no attached room
  // (TODO: Don't they get copied from the room data regardless?)
  if (nomination.year < 2020 || !nomination.roomId) {
    if (
        !nomination.room ||
        !nomination.company ||
        !nomination.country ||
        !nomination.link
    ) {
      return false;
    }
    if (
        nomination.category !== TerpecaCategory.TOP_ONLINE_ROOM &&
        !nomination.city
    ) {
      return false;
    }
  }

  // Starting in 2020, check confirmation fields.
  if (nomination.year >= 2020) {
    if (!nomination.confirmedNoConflicts) {
      return false;
    }
    // New-to-TERPECA rooms require nominator confirmation of openness and English availability to remind nominators of the
    // criteria and provide a first filter. Room reviewers cross-check eligibility for all nominated rooms regardless.
    if (!nomination.roomId) {
      if (![true, false].includes(nomination.confirmedDates)) {
        return false;
      }
      if (
          !isEnglishSpeaking(nomination.country, nomination.state) &&
          ![true, false].includes(nomination.confirmedEnglish)
      ) {
        return false;
      }
    }
  }

  // Starting in 2022, check languages and horrorLevel.
  if (nomination.year >= 2022) {
    // New-to-TERPECA rooms require nominator input on language and horror level, as a starting point for reviewers. Room reviewers
    // cross-check these properties for all nominated rooms regardless. If a nominator disagrees with either of these on a room where
    // they have already been set, they can provide feedback via the dataProblem field, which will be visible to reviewers to consider.
    if (!nomination.roomId) {
      if (!isEnglishSpeaking(nomination.country, nomination.state) && !nomination.languages?.length) {
        return false;
      }

      if (!nomination.horrorLevel) {
        return false;
      }
    }
  }

  return true;
}

export function isConfirmedOpen(room: TerpecaRoom, year?: number) {
  return isApproved(room, year) || (room.isConfirmedOpen && room.isConfirmedOpen.includes(year || environment.currentAwardYear));
}

export function isConfirmedEnglish(room: TerpecaRoom, year?: number) {
  return isApproved(room, year) || isEnglishSpeaking(room.country, room.state) || (room.isConfirmedEnglish &&
      room.isConfirmedEnglish.includes(year || environment.currentAwardYear));
}

export function isApproved(room: TerpecaRoom, year?: number) {
  return room.isApproved && room.isApproved.includes(year || environment.currentAwardYear);
}

export function isIneligible(room: TerpecaRoom, year?: number) {
  return room.isIneligible?.includes(year || environment.currentAwardYear) || isPermanentlyIneligible(room, year);
}

export function isPermanentlyIneligible(room: TerpecaRoom, year?: number) {
  return room.isPermanentlyIneligible?.length && room.isPermanentlyIneligible[0] <= (year || environment.currentAwardYear);
}

export function isAutomaticFinalist(room: TerpecaRoom, year?: number) {
  year = year || environment.currentAwardYear;
  return year >= 2022 && isWinner(room, year - 1) && !isIneligible(room, year);
}

export function isNominee(room: TerpecaRoom, year?: number) {
  return room.isNominee && room.isNominee.includes(year || environment.currentAwardYear);
}

export function latestNomineeYear(room: TerpecaRoom) {
  return Math.max(...room.isNominee);
}

export function numNominations(room: TerpecaRoom, year?: number) {
  return isNominee(room, year) ? (room.nominations || []).filter(key => key.year === year).length : 0;
}

export function isFinalist(room: TerpecaRoom, year?: number) {
  return room.isFinalist && room.isFinalist.includes(year || environment.currentAwardYear);
}

export function isWinner(room: TerpecaRoom, year?: number) {
  return room.isWinner && room.isWinner.includes(year || environment.currentAwardYear);
}

export function isNewRoom(room: TerpecaRoom, year: number) {
  if (!isNominee(room, year)) {
    return false;
  }
  for (const y of room.isNominee) {
    if (y < year) {
      return false;
    }
  }
  return true;
}

export function canonicalize(a: any, field: string): string {
  if (!a || !(field in a) || !a[field] || a[field].length === 0) {
    return '';
  }
  return normalize(a[field]);
}

export function normalize(s: string): string {
  return s.trim().normalize('NFD').replace(/\p{Diacritic}/gu, '').toLocaleLowerCase();
}

export function trimInPlace(a: any) {
  for (const field of Object.keys(a)) {
    const value = a[field];
    if (value && typeof value === 'string' && value.length > 0) {
      a[field] = value.trim();
    }
  }
}

export function getCountriesForRoom(room: (TerpecaRoom | TerpecaNomination)) {
  const countries = [];
  if (room.country) {
    countries.push(room.country);
  }
  if (room.city.includes(' / ')) {
    const locations = room.city.split(' / ');
    for (const location of locations) {
      if (location.includes(', ')) {
        const locationParts = location.split(', ');
        const country = locationParts[locationParts.length - 1];
        if (country === 'UK') {
          countries.push('United Kingdom');
        } else if (country === 'USA') {
          countries.push('United States');
        } else if (country.length > 3) {
          countries.push(country);
        }
      }
    }
  }
  return countries;
}

export function getStatesForRoom(room: (TerpecaRoom | TerpecaNomination), country: string) {
  const countries = getCountriesForRoom(room);
  const states = [];
  if (['Canada', 'United States'].includes(country) && countries.includes(country)) {
    if (room.country === country && room.state) {
      states.push(room.state);
    }
    if (room.city.includes(' / ')) {
      const locations = room.city.split(' / ');
      for (const location of locations) {
        if (location.includes(', ')) {
          const locationParts = location.split(', ');
          if (country === 'Canada') {
            if ((locationParts.length === 3 && locationParts[2] === 'Canada') ||
                (locationParts.length === 2 && room.country === 'Canada' && locationParts[1].length === 2)) {
              states.push(locationParts[1]);
            }
          }
          if (country === 'United States') {
            if ((locationParts.length === 3 && locationParts[2] === 'USA') ||
                (locationParts.length === 2 && room.country === 'United States' && locationParts[1].length === 2)) {
              states.push(locationParts[1]);
            }
          }
        }
      }
    }
  }
  return states;
}

@Pipe({ name: 'roomFilter' })
export class RoomFilter implements PipeTransform {
  transform(rooms: (TerpecaRoom | TerpecaNomination)[], countries: string, states: string, searchText: string, includeNotes?: boolean,
            compareFn?: (a: (TerpecaRoom | TerpecaNomination), b: (TerpecaRoom | TerpecaNomination)) => number,
            maxResults?: number): any[] {
    if (!rooms) {
      return [];
    }
    if (countries?.length) {
      const countryList = countries.split(', ');
      if (countryList.length) {
        rooms = rooms.filter(room => {
          const roomCountries = getCountriesForRoom(room);
          for (const country of roomCountries) {
            if (countryList.includes(country)) {
              if (states?.length && ['Canada', 'United States'].includes(country)) {
                const stateList = states.split(', ');
                if (stateList.length) {
                  const roomStates = getStatesForRoom(room, country);
                  for (const state of roomStates) {
                    if (stateList.includes(state)) {
                      return true;
                    }
                  }
                  return false;
                }
              }
              return true;
            }
          }
          return false;
        });
      }
    }
    if (searchText) {
      const searchArray = searchText.toLocaleLowerCase().split(' ');
      if (searchArray.length > 0) {
        rooms = rooms.filter(room => {
          for (const token of searchArray) {
            let found = false;
            for (const s of [(<TerpecaRoom>room).name, (<TerpecaRoom>room).englishName, (<TerpecaNomination>room).room,
                            room.company, room.country, room.city, room.state, room.link]) {
              if (s && normalize(s).includes(normalize(token))) {
                found = true;
                break;
              }
            }
            if (!found && includeNotes) {
              for (const s of [(<TerpecaRoom>room).reviewerNotes, (<TerpecaRoom>room).ineligibilityReason]) {
                if (s && normalize(s).includes(normalize(token))) {
                  found = true;
                  break;
                }
              }
            }
            if (!found) {
              return false;
            }
          }
          return true;
        });
      }
    }
    if (compareFn) {
      rooms = rooms.sort(compareFn);
    }
    if (maxResults) {
      rooms = rooms.slice(0, maxResults);
    }
    return rooms;
  }
}

@Pipe({ name: 'userFilter' })
export class UserFilter implements PipeTransform {
  transform(users: (TerpecaUser | TerpecaUserDisclosure)[], countries: string, searchText: string,
            compareFn?: (a: (TerpecaUser | TerpecaUserDisclosure), b: (TerpecaUser | TerpecaUserDisclosure)) => number): any[] {
    if (!users) {
      return [];
    }
    if (countries?.length) {
      const countryList = countries.split(', ');
      if (countryList.length) {
        users = users.filter(user => countryList.includes(user.country));
      }
    }
    if (searchText) {
      const searchArray = searchText.toLocaleLowerCase().split(' ');
      if (searchArray.length > 0) {
        users = users.filter(user => {
          for (const token of searchArray) {
            let found = false;
            for (const s of [user.realName, user.city, user.state, user.country]) {
              if (s && s.toLocaleLowerCase().includes(token)) {
                found = true;
                break;
              }
            }
            if (!found) {
              return false;
            }
          }
          return true;
        });
      }
    }
    if (compareFn) {
      users = users.sort(compareFn);
    }
    return users;
  }
}
