<section>
  <div class="flex-col justify-center align-center gap-5">
    <h3 class="flex-row justify-center align-center">
      TERPECA Room Finder
    </h3>
    <form [formGroup]="formGroup" class="flex-col justify-center align-center gap-5">
      <div class="flex-row flex-wrap justify-center gap-5">
        <mat-form-field appearance="fill">
          <mat-label>Year</mat-label>
          <mat-select matNativeControl formControlName="year">
            <mat-option [value]="ALL_YEARS">all years (beta)</mat-option>
            <mat-option *ngFor="let y of allYears()" [value]=y>{{ y }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Country</mat-label>
          <mat-select matNativeControl formControlName="countryFilter" (ngModelChange)="formGroup.patchValue({stateFilter:''});">
            <mat-option value="">all countries</mat-option>
            <mat-option *ngFor="let c of allCountries" [value]=c>{{ c }}</mat-option>
          </mat-select>
        </mat-form-field>
        <span *ngIf="formGroup.value.countryFilter === 'United States'">
          <mat-form-field appearance="fill">
            <mat-label>State</mat-label>
            <mat-select matNativeControl formControlName="stateFilter">
              <mat-option value="">all states</mat-option>
              <mat-option *ngFor="let s of allStates" [value]=s>{{ getStateName(s) }}</mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <span *ngIf="formGroup.value.countryFilter === 'Canada'">
          <mat-form-field appearance="fill">
            <mat-label>Province</mat-label>
            <mat-select matNativeControl formControlName="stateFilter">
              <mat-option value="">all provinces</mat-option>
              <mat-option *ngFor="let p of allProvinces" [value]=p>{{ getStateName(p) }}</mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <mat-form-field appearance="fill" style="min-width: 200px;">
          <mat-label>Horror Level</mat-label>
          <mat-select matNativeControl formControlName="horrorLevelFilter" multiple>
            <mat-select-trigger>
              <span *ngIf="formGroup.value.horrorLevelFilter?.length < allHorrorLevels.length">
                <span *ngFor="let h of formGroup.value.horrorLevelFilter">
                  <app-horrorlevel [level]=h [showShortText]=true></app-horrorlevel>
                </span>
              </span>
              <span *ngIf="formGroup.value.horrorLevelFilter?.length === allHorrorLevels.length">
                all horror levels
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let h of allHorrorLevels" [value]=h>
              <app-horrorlevel [level]=h [showShortText]=true></app-horrorlevel>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Language</mat-label>
          <mat-select matNativeControl formControlName="languageFilter">
            <mat-option value="">all languages</mat-option>
            <mat-option value="en">English</mat-option>
            <mat-option *ngFor="let l of allLanguages" [value]=l>{{ languageName(l) }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex-row justify-center align-center gap-10">
        <div>additional search terms:</div>
        <input class="search-box" type="string" matInput formControlName="searchText">
      </div>
    </form>
    <div *ngIf="loadingInProgress">
      <i>Loading...</i>
    </div>
    <div *ngIf="!loadingInProgress" class="flex-default" style="width: 95%; overflow-x: auto;">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="rank">
          <th mat-header-cell *matHeaderCellDef>{{showingAllYears() ? "Latest" : formGroup.value.year}} Rank</th>
          <td mat-cell *matCellDef="let room">
            <app-roomfinderrank [room]="room" [year]="yearToShow(room)" [showYear]="showingAllYears()" [canShowRanks]="canShowRanks(yearToShow(room))">
            </app-roomfinderrank>
          </td>
        </ng-container>
        <ng-container matColumnDef="room">
          <th mat-header-cell *matHeaderCellDef>Room</th>
          <td mat-cell *matCellDef="let room">
            <a href="{{ room.link }}" target="_blank">
              <strong>{{ room.englishName || room.name }} <span *ngIf="room.englishName">[{{ room.name }}]</span></strong>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>Company</th>
          <td mat-cell *matCellDef="let room">{{room.company}}</td>
        </ng-container>
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef>City / State</th>
          <td mat-cell *matCellDef="let room">{{locationName(room, true)}}</td>
        </ng-container>
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef>Country</th>
          <td mat-cell *matCellDef="let room">{{room.country}}</td>
        </ng-container>
        <ng-container matColumnDef="tags">
          <th mat-header-cell *matHeaderCellDef>Tags</th>
          <td mat-cell *matCellDef="let room">
            <span *ngIf="isClosed(room)" matTooltip="Closed">
              ⛔
            </span>
            <span *ngIf="!isClosed(room)">
              <app-horrorlevel [level]="horrorLevel(room)"></app-horrorlevel>
              <span *ngFor="let l of languages(room)">{{' '}}<app-language [alpha2]="l"></app-language></span>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="lastRank">
          <th mat-header-cell *matHeaderCellDef>{{showingAllYears() ? "Previous" : formGroup.value.year - 1}} Rank</th>
          <td mat-cell *matCellDef="let room">
            <app-roomfinderrank [room]="room" [year]="yearToShow(room) - 1" [showYear]="showingAllYears()" [canShowRanks]="canShowRanks(yearToShow(room) - 1)">
            </app-roomfinderrank>
          </td>
        </ng-container>

        <ng-container matColumnDef="bestRank">
          <th mat-header-cell *matHeaderCellDef>Best Rank</th>
          <td mat-cell *matCellDef="let room">
            <app-roomfinderrank [room]="room" [year]="bestYear(room)" [showYear]="true" [hideNew]="true" [canShowRanks]="canShowRanks(bestYear(room))">
            </app-roomfinderrank>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="roomColumns"></tr>
        <tr mat-row *matRowDef="let room; columns: roomColumns;"
            [ngClass]="{'winner-row': isWinner(room, yearForRowHighlight(room)) && canShowRanks(yearForRowHighlight(room)),
                        'non-winner-finalist-row': !(isWinner(room, yearForRowHighlight(room)) && canShowRanks(yearForRowHighlight(room))) && isFinalist(room, yearForRowHighlight(room)),
                        'non-finalist-nominee-row': !isFinalist(room, yearForRowHighlight(room))}"></tr>
      </table>
    </div>
    <div *ngIf="!loadingInProgress">
      <p>
        <i>This view includes {{numRoomsShowing}} of the {{numRoomsLoaded}} total rooms in the database.</i>
      </p>
      <p *ngIf="hasUnrankedRooms">
        <i>
          Note: Rooms in this list that appear with a rank of NR indicate that multiple versions of the room were ranked independently, but
          only the highest finishing version was given an official rank.<br>Versions with lower rankings are also considered Top Room winners if
          they independently ranked high enough, even if they weren't given an official ranking.
        </i>
      </p>
      <p *ngIf="hasDuplicateRanks">
        <i>
          Note: Rooms in this list that appear with the same ranking indicate that multiple versions of the room were combined during the data
          analysis phase.
        </i>
      </p>
    </div>
  </div>
</section>
