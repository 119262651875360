import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';

import { GlobalSettings, TerpecaSettings } from 'src/app/models/settings.model';
import { ApplicationStatus, TerpecaUser } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';

import { TerpecaCategory } from '../models/room.model';
import { VersionUpdatedDialogComponent } from '../views/versionupdateddialog/versionupdateddialog.component';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private settingsMap: Map<number, TerpecaSettings>;
  now: Date = new Date();
  allYears: number[];
  private version: string = null;
  private MIN_YEAR = 2018;
  private year = environment.currentAwardYear;

  constructor(private db: AngularFirestore, private dialog: MatDialog) {
    setInterval(() => {
      this.now = new Date();
    }, 1000);
    this.allYears = [];
    for (let y = this.MIN_YEAR; y <= this.year; ++y) {
      this.allYears.push(y);
    }
    this.settingsMap = new Map<number, TerpecaSettings>();
    this.db.doc<GlobalSettings>(`settings/global`).valueChanges()
    .subscribe((settings: GlobalSettings) => {
      if (settings) {
        if (this.version === null) {
          this.version = settings.version;
        } else if (this.version !== settings.version) {
          this.dialog.open(VersionUpdatedDialogComponent, null);
        }
      }
    });
  }

  async loadSettings(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.db.collection<TerpecaSettings>('settings', ref => ref.where('year', '>=', this.MIN_YEAR)).valueChanges()
      .subscribe((settingsList: TerpecaSettings[]) => {
        if (settingsList) {
          for (const settings of settingsList) {
            this.settingsMap.set(settings.year, settings);
          }
        }
        resolve();
      });
    });
  }

  private timeToNominationDeadlineMillis() {
    if (this.settingsMap.get(this.year)?.nominationDeadline) {
      return this.settingsMap.get(this.year).nominationDeadline.toDate().getTime() - this.now.getTime();
    }
    return 0;
  }

  private timeToVotingDeadlineMillis() {
    if (this.settingsMap.get(this.year)?.votingDeadline) {
      return this.settingsMap.get(this.year).votingDeadline.toDate().getTime() - this.now.getTime();
    }
    return 0;
  }

  private deadlineString(date: Date) {
    if (!date) {
      return '';
    }
    const options = <const>{
      year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric',
      minute: '2-digit', second: '2-digit', timeZoneName: 'short'
    };
    return date.toLocaleDateString(undefined, options);
  }

  private timeToDeadlineString(millis: number) {
    if (millis <= 0) {
      return 'EXPIRED';
    }
    const seconds = Math.floor(millis / 1000);
    if (seconds < 60) {
      return `${seconds} second${seconds > 1 ? 's' : ''}`;
    }
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `${hours} hour${hours > 1 ? 's' : ''}`;
    }
    const days = Math.floor(hours / 24);
    return `${days} day${days > 1 ? 's' : ''}`;
  }

  isCurrentYear(year: number) {
    return year === this.year;
  }

  isFinishedYear(year: number) {
    return year < this.year || this.settingsMap.get(year)?.sendWinnerEmails;
  }

  finishedYears() {
    return this.allYears.filter(key => this.isFinishedYear(key));
  }

  isNominationOpen(user?: TerpecaUser) {
    if (user && user.status >= ApplicationStatus.REVIEWER && this.settingsMap.get(this.year)?.earlyNominationOpen) {
      return true;
    }
    return Boolean(this.settingsMap.get(this.year)?.nominationOpen);
  }

  finalistThreshold(year?: number) {
    return this.settingsMap.get(year || this.year)?.finalistThreshold || 2;
  }

  numWinners(category: TerpecaCategory, year?: number) {
    switch (category) {
      case TerpecaCategory.TOP_ROOM:
        return this.settingsMap.get(year || this.year)?.numTopRoomWinners || 50;
      case TerpecaCategory.TOP_ONLINE_ROOM:
        return this.settingsMap.get(year || this.year)?.numTopOnlineRoomWinners || 0;
      case TerpecaCategory.TOP_COMPANY:
        return this.settingsMap.get(year || this.year)?.numTopCompanyWinners || 25;
    }
  }

  nominationDeadlineString() {
    if (!this.settingsMap.get(this.year)?.nominationDeadline) {
      return '';
    }
    return this.deadlineString(this.settingsMap.get(this.year).nominationDeadline.toDate());
  }

  timeToNominationDeadlineString() {
    return this.timeToDeadlineString(this.timeToNominationDeadlineMillis());
  }

  isPastNominationDeadline() {
    return this.timeToNominationDeadlineMillis() <= 0;
  }

  votingDeadlineString() {
    if (!this.settingsMap.get(this.year)?.votingDeadline) {
      return '';
    }
    return this.deadlineString(this.settingsMap.get(this.year).votingDeadline.toDate());
  }

  timeToVotingDeadlineString() {
    return this.timeToDeadlineString(this.timeToVotingDeadlineMillis());
  }

  isVotingOpen(user?: TerpecaUser) {
    if (user && user.status >= ApplicationStatus.REVIEWER && this.settingsMap.get(this.year)?.earlyVotingOpen) {
      return true;
    }
    return Boolean(this.settingsMap.get(this.year)?.votingOpen);
  }

  isNearVotingDeadline() {
    return !this.isPastVotingDeadline() && this.timeToVotingDeadlineMillis() < 172800000;
  }

  isPastVotingDeadline() {
    return this.timeToVotingDeadlineMillis() <= 0;
  }

  votedAwardCategories() {
    if (this.year === 2018) {
      return [TerpecaCategory.TOP_COMPANY, TerpecaCategory.TOP_ROOM];
    }
    if ([2020, 2021].includes(this.year)) {
      return [TerpecaCategory.TOP_ROOM, TerpecaCategory.TOP_ONLINE_ROOM];
    }
    return [TerpecaCategory.TOP_ROOM];
  }

  hasOnlineRoomCategory() {
    return this.votedAwardCategories().includes(TerpecaCategory.TOP_ONLINE_ROOM);
  }

  hasCompanyCategory() {
    return this.votedAwardCategories().includes(TerpecaCategory.TOP_COMPANY);
  }
}
