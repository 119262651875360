import { Component, Input } from '@angular/core';

import { TerpecaRankedEntity } from 'src/app/models/results.model';
import { TerpecaRoom } from 'src/app/models/room.model';
import { isFinalist, isNewRoom, isNominee, isWinner, numNominations } from 'src/app/utils/misc.utils';

@Component({
  selector: 'app-roomfinderrank',
  templateUrl: './roomfinderrank.component.html',
  styleUrl: './roomfinderrank.component.css',
})
export class RoomFinderRankComponent {
  @Input() room: TerpecaRoom;
  @Input() year: number;
  @Input() showYear: boolean;
  @Input() hideNew: boolean;
  @Input() canShowRanks: boolean;

  constructor() { }

  yearStr() {
    if (this.showYear && isNominee(this.room, this.year)) {
      return this.year;
    }
    return '';
  }

  isNew() {
    return isNewRoom(this.room, this.year);
  }

  emoji() {
    if (isFinalist(this.room, this.year)) {
      return (this.canShowRanks && isWinner(this.room, this.year)) ? '🏆' : '🏅';
    }
    return isNominee(this.room, this.year) ? '🎗️' : '';
  }

  tooltip() {
    if (isFinalist(this.room, this.year)) {
      return `${this.year} Top Room ${(this.canShowRanks && isWinner(this.room, this.year)) ? 'Winner' : 'Finalist'}`;
    }
    return isNominee(this.room, this.year) ? `${this.year} Top Room Nominee` : '';
  }

  rankStr() {
    if (isFinalist(this.room, this.year)) {
      if (this.canShowRanks) {
        if (this.room.resultsData && this.room.resultsData[this.year]) {
          const entity: TerpecaRankedEntity = this.room.resultsData[this.year];
          return `#${entity.unranked ? 'NR' : entity.rank}`;
        } else {
          return 'n/a';
        }
      } else {
        return 'TBD';
      }
    } else if (isNominee(this.room, this.year)) {
      if (this.canShowRanks) {
        return `x${numNominations(this.room, this.year)}`;
      } else {
        return 'TBA';
      }
    }
    return 'n/a';
  }
}
