import { Component } from '@angular/core';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore';

import app from 'firebase/compat/app';

import { TerpecaNomination } from 'src/app/models/nomination.model';
import { TerpecaQuote, maxQuotesPerYear } from 'src/app/models/quote.model';
import { TerpecaRanking, UnrankedReason, getUnrankedIds } from 'src/app/models/ranking.model';
import { TerpecaCategory, TerpecaRoom } from 'src/app/models/room.model';
import {
    ApplicationStatus, TerpecaUser, TerpecaUserDisclosure, getIpAddressesFromAuditLog, getUserAuditLogString
} from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import * as utils from 'src/app/utils/misc.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrl: './validate.component.css'
})
export class ValidateComponent {
  year = environment.currentAwardYear;
  Status = ApplicationStatus;
  allowValidate = true;
  allowFix = false;
  log: string[] = ['not started'];
  users: Map<string, TerpecaUser>;
  disclosures: Map<string, TerpecaUserDisclosure>;
  ipAddresses: Map<string, string[]>;  // maps IP address to list of userIDs
  userNominations: Map<string, TerpecaNomination[]>;
  nominations: Map<string, TerpecaNomination>;
  nominationsSubmitted: number;
  rooms: Map<string, TerpecaRoom>;
  regularRankings: Map<string, TerpecaRanking>;
  regularRankingsSubmitted: number;
  onlineRankings: Map<string, TerpecaRanking>;
  onlineRankingsSubmitted: number;
  quotes: TerpecaQuote[];
  quotesSubmitted: number;
  warnings: string[];
  issues: string[];
  fixableIssues: string[];
  fixes: string[];

  constructor(public auth: AuthService, public settings: SettingsService, private db: AngularFirestore) { }

  async validate(fix: boolean) {
    this.allowValidate = false;
    this.allowFix = false;
    this.log = [];
    this.issues = [];
    this.fixableIssues = [];
    this.warnings = [];
    this.fixes = [];
    this.ipAddresses = new Map<string, string[]>();
    await this.loadNominations();
    await this.loadRooms();
    await this.loadRankings();
    await this.loadQuotes();
    await this.loadDisclosures();
    await this.validateUsers(fix);
    await this.validateNominations(fix);
    await this.validateRooms(fix);
    await this.validateRankings(TerpecaCategory.TOP_ROOM);
    await this.validateRankings(TerpecaCategory.TOP_ONLINE_ROOM);
    await this.validateQuotes();
    this.log.push(`validation complete`, `warnings found: ${this.warnings.length}`,
                  `issues found: ${this.issues.length + this.fixableIssues.length + this.fixes.length}`);
    if (fix) {
      this.log.push(`fixes made: ${this.fixes.length}`);
    } else {
      this.log.push(`fixable issues found: ${this.fixableIssues.length}`);
    }
    this.allowValidate = true;
    this.allowFix = !fix && this.fixableIssues.length > 0;
  }

  async loadNominations() {
    this.users = new Map<string, TerpecaUser>();
    this.userNominations = new Map<string, TerpecaNomination[]>();
    this.nominations = new Map<string, TerpecaNomination>();
    this.nominationsSubmitted = 0;
    await this.db.collection<TerpecaNomination>('nominations').ref
    .where('year', '==', environment.currentAwardYear).get()
    .then((snapshot: QuerySnapshot<TerpecaNomination>) => {
      for (const doc of snapshot.docs) {
        const nomination: TerpecaNomination = doc.data();
        nomination.docId = doc.id;
        if (!nomination.userId) {
          this.issues.push(`nomination without userID: ${nomination.room}`);
        } else {
          this.getUser(nomination.userId).then((user: TerpecaUser) => {
            if (!user) {
              this.issues.push(`nomination has missing user: ${nomination.room}`);
            } else {
              if (!this.userNominations.has(user.uid)) {
                this.userNominations.set(user.uid, []);
              }
              this.userNominations.get(user.uid).push(nomination);
            }
          });
        }
        this.nominations.set(nomination.docId, nomination);
        if (!nomination.pending) {
          this.nominationsSubmitted++;
        }
      }
      this.log.push(`loaded ${this.nominations.size} nominations (${this.nominationsSubmitted} submitted)`);
    });
  }

  async loadRooms() {
    this.rooms = new Map<string, TerpecaRoom>();
    await this.db.collection<TerpecaRoom>('rooms').ref.get()
    .then((snapshot: QuerySnapshot<TerpecaRoom>) => {
      for (const doc of snapshot.docs) {
        const room: TerpecaRoom = doc.data();
        room.docId = doc.id;
        this.rooms.set(room.docId, room);
      }
      this.log.push(`loaded ${this.rooms.size} rooms`);
    });
  }

  async loadRankings() {
    this.regularRankings = new Map<string, TerpecaRanking>();
    this.onlineRankings = new Map<string, TerpecaRanking>();
    this.regularRankingsSubmitted = 0;
    this.onlineRankingsSubmitted = 0;
    await this.db.collection<TerpecaRanking>('rankings').ref
    .where('year', '==', environment.currentAwardYear).get()
    .then((snapshot: QuerySnapshot<TerpecaRanking>) => {
      for (const doc of snapshot.docs) {
        const ranking: TerpecaRanking = doc.data();
        ranking.docId = doc.id;
        if (!ranking.userId) {
          this.issues.push(`ranking without userID: ${ranking.docId}`);
        } else {
          this.getUser(ranking.userId).then((user: TerpecaUser) => {
            if (!user) {
              this.issues.push(`ranking has missing user: ${ranking.docId}`);
            }
          });
          if (ranking.category === TerpecaCategory.TOP_ROOM) {
            this.regularRankings.set(ranking.userId, ranking);
            if (ranking.submitted) {
              this.regularRankingsSubmitted++;
            }
          } else {
            this.onlineRankings.set(ranking.userId, ranking);
            if (ranking.submitted) {
              this.onlineRankingsSubmitted++;
            }
          }
        }
      }
      this.log.push(`loaded ${this.regularRankings.size} in-person rankings (${this.regularRankingsSubmitted} submitted)`);
      this.log.push(`loaded ${this.onlineRankings.size} online rankings (${this.onlineRankingsSubmitted} submitted)`);
    });
  }

  async loadQuotes() {
    this.quotes = [];
    await this.db.collection<TerpecaQuote>('quotes').ref
    .where('year', '==', environment.currentAwardYear)
    .where('submitted', '==', true).get()
    .then((snapshot: QuerySnapshot<TerpecaQuote>) => {
      for (const doc of snapshot.docs) {
        const quote: TerpecaQuote = doc.data();
        quote.docId = doc.id;
        if (!quote.roomId) {
          this.issues.push(`quote without roomID: ${quote.docId}`);
        }
        if (!quote.userId) {
          this.issues.push(`quote without userID: ${quote.docId}`);
        } else {
          this.getUser(quote.userId).then((user: TerpecaUser) => {
            if (!user) {
              this.issues.push(`quote has missing user: ${quote.docId}`);
            }
          });
          this.quotes.push(quote);
        }
      }
      this.log.push(`loaded ${this.quotes.length} quotes`);
    });
  }

  async loadDisclosures() {
    this.disclosures = new Map<string, TerpecaUserDisclosure>();
    await this.db.collection<TerpecaUserDisclosure>('disclosures').ref
    .where('year', '==', environment.currentAwardYear).get()
    .then((snapshot: QuerySnapshot<TerpecaUserDisclosure>) => {
      for (const doc of snapshot.docs) {
        const disclosure: TerpecaUserDisclosure = doc.data();
        disclosure.docId = doc.id;
        if (disclosure.status >= ApplicationStatus.VOTER) {
          if (!disclosure.userId) {
            this.issues.push(`disclosure without userID: ${disclosure.docId}`);
          } else {
            this.getUser(disclosure.userId).then((user: TerpecaUser) => {
              if (!user) {
                this.issues.push(`disclosure has missing user: ${disclosure.docId}`);
              }
            });
            this.disclosures.set(disclosure.userId, disclosure);
          }
        }
      }
      this.log.push(`loaded ${this.disclosures.size} disclosures`);
      this.log.push(`loaded ${this.users.size} users`);
    });
  }

  async getUser(userId: string) {
    if (!this.users.has(userId)) {
      await this.db.collection<TerpecaUser>('users').doc(userId).ref.get().then(snapshot => {
        if (snapshot.exists) {
          const user: TerpecaUser = <TerpecaUser>snapshot.data();
          if (user.status < ApplicationStatus.VOTER) {
            this.issues.push(`loading user ${user.realName} with status ${user.status}`);
          }
          this.users.set(userId, user);
          for (const ip of user.ipAddresses || getIpAddressesFromAuditLog(user)) {
            if (!this.ipAddresses.has(ip)) {
              this.ipAddresses.set(ip, [userId]);
            } else {
              const storedUserIds = this.ipAddresses.get(ip);
              if (!storedUserIds.includes(userId)) {
                storedUserIds.push(userId);
              }
            }
          }
        } else {
          this.issues.push(`user missing: ${userId}`);
        }
      }).catch((_error: unknown) => {
        this.issues.push(`error loading user: ${userId}`);
      });
    }
    return this.users.get(userId);
  }

  async validateUsers(fix: boolean) {
    for (const key of this.users.keys()) {
      const user: TerpecaUser = this.users.get(key);
      if (!user) {
        this.issues.push(`user missing: ${key}`);
        continue;
      }
      const nomList = this.userNominations.get(key);
      if (nomList && nomList.length > 0) {
        if (user.status < ApplicationStatus.NOMINATOR) {
          this.issues.push(`user ${user.realName} submitted ${nomList.length} nominations, but is not an approved nominator!`);
        }
        const numRegularNoms = nomList.filter(nom => nom.category === TerpecaCategory.TOP_ROOM).length;
        const numOnlineNoms = nomList.filter(nom => nom.category === TerpecaCategory.TOP_ONLINE_ROOM).length;
        if (!user.nominationsSubmitted || !user.nominationsSubmitted.includes(this.year)) {
          if (this.settings.isPastNominationDeadline()) {
            this.issues.push(
              `user ${user.realName} has ${nomList.length} (${numRegularNoms}+${numOnlineNoms}) nominations, but did not submit!`);
          } else {
            this.warnings.push(
              `user ${user.realName} has ${nomList.length} (${numRegularNoms}+${numOnlineNoms}) unsubmitted nominations.`);
          }
        }
        const roomIdMap = new Map<string, TerpecaNomination>();
        if (user.nominationsSubmitted?.includes(this.year)) {
          if (numRegularNoms > utils.maxRegularNoms(user)) {
            this.issues.push(`user ${user.realName} has too many regular nominations: ${numRegularNoms} (max: ${utils.maxRegularNoms(user)})`);
          }
          if (numOnlineNoms > utils.maxOnlineNoms(user)) {
            this.issues.push(`user ${user.realName} has too many online nominations: ${numOnlineNoms} (max: ${utils.maxOnlineNoms(user)})`);
          }
          // We look here for the presence of strategic nominations from anyone with 20+ rankings last year, which we arbitrarily define as:
          //   - not nominating a room from your previous year's top 5 that you could have nominated
          //   - nominating a room that you ranked lower than 20th among all still eligible rooms from the previous year
          // For now, we're just monitoring this to see if there is an issue, and we don't bother with these once voting is open.
          const lastVoterYear = utils.lastVoterYear(user, this.year);
          if (!this.settings.isVotingOpen() && lastVoterYear === this.year - 1) {
            const lastRankingDocId = `${user.uid}-${lastVoterYear}-${TerpecaCategory.TOP_ROOM}`;
            const lastRanking: TerpecaRanking = await this.db.collection<TerpecaRanking>('rankings').doc(lastRankingDocId).ref
              .get().then(lastRankingDoc => (lastRankingDoc.exists ? <TerpecaRanking>lastRankingDoc.data() : null));
            if (lastRanking && lastRanking.rankedIds?.length > 20) {
              const nominatedRoomIds = new Set<string>();
              for (const nom of nomList) {
                nominatedRoomIds.add(nom.roomId);
              }
              let expectedRank = 0;  // This is the rank expected if there were no new rooms this year.
              for (let rank = 0; rank < lastRanking.rankedIds.length; ++rank) {
                const roomId = lastRanking.rankedIds[rank];
                const room = this.rooms.get(lastRanking.rankedIds[rank]);
                if (user.affiliatedRoomIds?.includes(roomId) || utils.isIneligible(room) || room.isWinner?.includes(this.year - 1)) {
                  // These are all the reasons why the user may not have nominated the room this year.
                  continue;
                }
                if (rank < 5) {
                  if (!nominatedRoomIds.has(lastRanking.rankedIds[rank])) {
                    this.warnings.push(
                      `user ${user.realName} nominated ${nomList.length} rooms, but didn't nominate "${room?.name}" which they ranked ` +
                      `very high (#${rank + 1}) in ${lastVoterYear} (expected: #${expectedRank + 1})`);
                  }
                } else if (expectedRank > 20) {
                  if (nominatedRoomIds.has(lastRanking.rankedIds[rank])) {
                    this.warnings.push(
                      `user ${user.realName} nominated "${room?.name}" which they ranked low (#${rank + 1}) in ${lastVoterYear} ` +
                      `(expected: #${expectedRank + 1})`);
                  }
                }
                ++expectedRank;
              }
            }
          }
        }
        for (const nom of nomList) {
          if (nom.roomId) {
            if (roomIdMap.has(nom.roomId)) {
              this.issues.push(
                `user ${user.realName} has multiple nominations for the same room (${this.rooms.get(nom.roomId).name}):` +
                ` ${nom.room}, ${roomIdMap.get(nom.roomId).room}`);
            } else {
              roomIdMap.set(nom.roomId, nom);
            }
          }
        }
      }
      const regularRanking = this.regularRankings.get(key);
      const onlineRanking = this.onlineRankings.get(key);
      if (regularRanking || onlineRanking) {
        if (user.status < ApplicationStatus.VOTER) {
          this.issues.push(`user ${user.realName} has rankings, but is not an approved voter!`);
        }
      }
      if (user.affiliatedRoomIds) {
        for (const roomId of user.affiliatedRoomIds) {
          const room = this.rooms.get(roomId);
          if (!room) {
            this.issues.push(`missing affiliated room listed for user ${user.realName}: ${roomId}`);
            continue;
          }
          if (!room.affiliatedUserIds || !room.affiliatedUserIds.includes(user.uid)) {
            this.issues.push(`room ${room.name} missing affiliated user ${user.realName}`);
            continue;
          }
        }
      }
      if (user.auditLogEntry) {
        if (!user.auditLogHistory?.map((entry) => getUserAuditLogString(entry)).includes(getUserAuditLogString(user.auditLogEntry))) {
          this.issues.push(
            `user ${user.realName}'s audit log was not updated on last update (${getUserAuditLogString(user.auditLogEntry)} vs ` +
            `${getUserAuditLogString(user.auditLogHistory[user.auditLogHistory.length - 1])})`);
        }
      }
      const disclosure = this.disclosures.get(key);
      if (!disclosure) {
        if (fix) {
          await this.updateDisclosureFromUserUpdate(user);
          if (regularRanking) {
            await this.updateDisclosureFromRanking(regularRanking);
          }
          if (onlineRanking) {
            await this.updateDisclosureFromRanking(onlineRanking);
          }
          this.fixes.push(`added new disclosure for user ${user.realName}`);
        } else {
          this.fixableIssues.push(`user ${user.realName} is missing disclosure`);
        }
      } else {
        if (regularRanking) {
          const disclosedIds = disclosure.rankedRoomIds || [];
          if (regularRanking.submitted) {
            const rankedIds = regularRanking.unsortedIds?.filter(
              id => regularRanking.year >= 2024 || !getUnrankedIds(regularRanking).includes(id)) || [];
            if (JSON.stringify(disclosedIds) !== JSON.stringify(rankedIds)) {
              if (fix) {
                await this.updateDisclosureFromRanking(regularRanking);
                this.fixes.push(`updated disclosure for ${user.realName} from in-person ranking info`);
              } else {
                this.fixableIssues.push(
                  ` disclosure for ${user.realName} doesn't match in-person ranking data` +
                  ` disclosure: ${JSON.stringify(disclosedIds)}` +
                  ` ranking: ${JSON.stringify(rankedIds)}`);
              }
            }
          } else if (disclosedIds.length > 0) {
            if (fix) {
              await this.updateDisclosureFromRanking(regularRanking);
              this.fixes.push(`updated disclosure for ${user.realName} to remove unsubmitted in-person rankings`);
            } else {
              this.fixableIssues.push(`disclosure for ${user.realName} has unsubmitted in-person rankings`);
            }
          }
        }
        if (onlineRanking) {
          const disclosedIds = disclosure.rankedOnlineRoomIds || [];
          if (onlineRanking.submitted) {
            const rankedIds = onlineRanking.unsortedIds?.filter(id => !getUnrankedIds(onlineRanking).includes(id)) || [];
            if (JSON.stringify(disclosedIds) !== JSON.stringify(rankedIds)) {
              if (fix) {
                await this.updateDisclosureFromRanking(onlineRanking);
                this.fixes.push(`updated disclosure for ${user.realName} from online ranking info`);
              } else {
                this.fixableIssues.push(
                  ` disclosure for ${user.realName} doesn't match online ranking data` +
                  ` disclosure: ${JSON.stringify(disclosedIds)}` +
                  ` ranking: ${JSON.stringify(rankedIds)}`);
              }
            }
          } else if (disclosedIds.length > 0) {
            if (fix) {
              await this.updateDisclosureFromRanking(onlineRanking);
              this.fixes.push(`updated disclosure for ${user.realName} to remove unsubmitted online rankings`);
            } else {
              this.fixableIssues.push(`disclosure for ${user.realName} has unsubmitted online rankings`);
            }
          }
        }
        if (!disclosure.userId || !disclosure.year) {
          if (fix) {
            await this.updateDisclosureFromUserUpdate(user);
            this.fixes.push(`updated disclosure for ${user.realName} from user object`);
          } else {
            this.fixableIssues.push(`disclosure for ${user.realName} missing user object`);
          }
        } else {
          if (disclosure.userId !== user.uid) {
            this.issues.push(`disclosure id ${disclosure.userId} doesn't match user id ${user.uid} for ${user.realName}`);
          }
          if (disclosure.year !== this.year) {
            this.issues.push(`disclosure field 'year' doesn't match expected year for ${user.realName}`);
          }
          const isContributor = (user.nominationsSubmitted || []).includes(this.year) || (user.rankingsSubmitted || []).includes(this.year);
          if (disclosure.isContributor !== isContributor) {
            this.issues.push(`disclosure field 'isContributor' doesn't match user for ${user.realName}`);
          }
          if ((JSON.stringify(disclosure.affiliatedRoomIds || [])) !== (JSON.stringify(user.affiliatedRoomIds || []))) {
            this.issues.push(`disclosure field 'affiliatedRoomIds' doesn't match user for ${user.realName}`);
          }
          for (const field of ['status', 'realName', 'roomCount', 'virtualRoomCount', 'city', 'state', 'country', 'bio']) {
            if ((disclosure[field] || null) !== (user[field] || null)) {
              this.issues.push(`disclosure field ${field} doesn't match for user ${user.realName}`);
            }
          }
        }
      }
    }
    for (const ipAddress of this.ipAddresses.keys()) {
      const storedUserIds = this.ipAddresses.get(ipAddress);
      if (storedUserIds.length > 2) {
        let message = `IP address ${ipAddress} shared by ${storedUserIds.length} users: `;
        const users = [];
        for (const userId of storedUserIds) {
          users.push(this.users.get(userId).realName);
        }
        message += users.join(', ');
        this.warnings.push(message);
      }
    }
  }

  async updateDisclosureFromUserUpdate(user: TerpecaUser) {
    const disclosure: Partial<TerpecaUserDisclosure> = {
      userId: user.uid,
      year: this.year,
      isContributor: (user.nominationsSubmitted || []).includes(this.year) || (user.rankingsSubmitted || []).includes(this.year)
    };
    for (const field of ['status', 'realName', 'roomCount', 'virtualRoomCount', 'city', 'state', 'country', 'bio', 'affiliatedRoomIds']) {
      if (field in user) {
        disclosure[field] = user[field];
      } else {
        disclosure[field] = app.firestore.FieldValue.delete();
      }
    }
    await this.db.firestore.collection('disclosures').doc(`${user.uid}-${this.year}`).set(disclosure, { merge: true });
  }

  async updateDisclosureFromRanking(ranking: TerpecaRanking) {
    const disclosure: Partial<TerpecaUserDisclosure> = { };
    switch (ranking.category) {
      case TerpecaCategory.TOP_ROOM:
        if (ranking.year >= 2024) {
          disclosure.rankedRoomIds = ranking.submitted ? (ranking.unsortedIds || []) : [];
        } else {
          disclosure.rankedRoomIds = ranking.submitted ?
            (ranking.unsortedIds?.filter(id => !getUnrankedIds(ranking).includes(id)) || []) : [];
        }
        break;
      case TerpecaCategory.TOP_ONLINE_ROOM:
        disclosure.rankedOnlineRoomIds = ranking.submitted ?
          (ranking.unsortedIds?.filter(id => !getUnrankedIds(ranking).includes(id)) || []) : [];
        break;
    }
    await this.db.firestore.collection('disclosures').doc(`${ranking.userId}-${this.year}`).set(disclosure, { merge: true });
  }

  async validateNominations(fix: boolean) {
    for (const key of this.nominations.keys()) {
      const nomination: TerpecaNomination = this.nominations.get(key);
      if (!Object.values(TerpecaCategory).includes(nomination.category)) {
        if (fix) {
          await this.db.firestore.collection('nominations').doc(`${nomination.docId}`).update({ category: TerpecaCategory.TOP_ROOM });
          this.fixes.push(`default category added to nomination: ${nomination.room}`);
        } else {
          this.fixableIssues.push(`nomination missing category: ${nomination.room}`);
        }
      }
      if (![true, false].includes(nomination.pending)) {
        if (fix) {
          await this.db.firestore.collection('nominations').doc(`${nomination.docId}`).update({ pending: false });
          this.fixes.push(`pending = false added to nomination: ${nomination.room}`);
        } else {
          this.fixableIssues.push(`nomination missing pending: ${nomination.room}`);
        }
      }
      const user: TerpecaUser = this.users.get(nomination.userId);
      if (!user) {
        this.issues.push(`nomination missing user: ${nomination.userId}`);
      }
      if (user?.nominationsSubmitted?.includes(this.year)) {
        if (nomination.pending) {
          this.issues.push(`submitted nomination by ${user?.realName} for room ${nomination.room} still marked as pending`);
        }
      } else {
        if (!nomination.pending) {
          this.issues.push(`unsubmitted nomination by ${user?.realName} for room ${nomination.room} not marked as pending`);
        }
      }
      if (this.settings.isPastNominationDeadline() && !nomination.roomId) {
        this.issues.push(`nomination by ${user?.realName} not assigned to room: ${nomination.room}`);
      }
      if (nomination.roomId) {
        const room: TerpecaRoom = this.rooms.get(nomination.roomId);
        if (!room) {
          this.issues.push(`missing room for nomination: ${nomination.room} by ${user?.realName}`);
        } else {
          let foundNomination = false;
          for (const nomId of room.nominations) {
            if (nomId.nominationId === nomination.docId && nomId.year === this.year) {
              foundNomination = true;
              break;
            }
          }
          if (!foundNomination && !nomination.pending) {
            this.issues.push(`room ${room.name} doesn't reference nomination ${nomination.room} by ${user?.realName}`);
          } else if (foundNomination && nomination.pending) {
            this.issues.push(`room ${room.name} references pending nomination ${nomination.room} by ${user?.realName}`);
          } else {
            if (room.category !== nomination.category) {
              this.issues.push(
                `room ${room.name} doesn't match category of nomination by ` +
                `${this.users.get(nomination.userId).realName} (${room.category} vs. ${nomination.category})`);
            }
          }
        }
        if (user?.affiliatedRoomIds && user.affiliatedRoomIds.includes(nomination.roomId)) {
          this.issues.push(`user ${user.realName} nominated affiliated room: ${room.name}`);
        }
      }
    }
  }

  async validateRooms(fix: boolean) {
    for (const key of this.rooms.keys()) {
      const room: TerpecaRoom = this.rooms.get(key);
      if (!Object.values(TerpecaCategory).includes(room.category)) {
        if (fix) {
          await this.db.firestore.collection('rooms').doc(`${room.docId}`).update({ category: TerpecaCategory.TOP_ROOM });
          this.fixes.push(`default category added to room: ${room.englishName || room.name}`);
        } else {
          this.fixableIssues.push(`room missing category: ${room.englishName || room.name}`);
        }
      }
      if (room.nominations) {
        for (const nomId of room.nominations) {
          if (nomId.year === this.year) {
            const nomination: TerpecaNomination = this.nominations.get(nomId.nominationId);
            if (!nomination) {
              this.issues.push(`missing nomination for room: ${room.name}`);
            } else {
              if (nomination.roomId !== room.docId) {
                this.issues.push(
                  `nomination ${nomination.room} by ${this.users.get(nomination.userId).realName} doesn't reference room ${room.name}`);
              }
            }
          }
        }
      }
    }
  }

  async validateRankings(category: TerpecaCategory) {
    const rankings = category === TerpecaCategory.TOP_ROOM ? this.regularRankings : this.onlineRankings;
    const descriptor = category === TerpecaCategory.TOP_ROOM ? 'in-person' : 'online';
    for (const key of rankings.keys()) {
      const ranking: TerpecaRanking = rankings.get(key);
      const user: TerpecaUser = this.users.get(ranking.userId);
      if (!user) {
        this.issues.push(`missing user ${ranking.userId} from stored ranking`);
        continue;
      }
      if (ranking.submitted) {
        const numUnsorted: number = ranking.unsortedIds?.length || 0;
        const numRanked: number = ranking.rankedIds?.length || 0;
        const numUnranked: number = getUnrankedIds(ranking).length || 0;
        const roomCount: number = category === TerpecaCategory.TOP_ROOM ? user.roomCount : user.virtualRoomCount;
        const suspicionThreshold: number = category === TerpecaCategory.TOP_ROOM ? 0.35 : 1.00;
        if (numUnsorted > suspicionThreshold * roomCount) {
          const percent = 100.0 * numUnsorted / roomCount;
          this.warnings.push(
            `${user.realName} has ranked a suspicious number of ${descriptor} rooms (${numUnsorted} of ${roomCount} - ${percent.toFixed(2)}%)`);
        }
        if (numUnsorted !== numRanked + numUnranked) {
          this.issues.push(`${user.realName} has different lengths for sorted (${numRanked} + ${numUnranked}) and unsorted (${numUnsorted}) ${descriptor} IDs`);
        }
        for (const roomId of ranking.unsortedIds || []) {
          if (!ranking.rankedIds?.includes(roomId) && !getUnrankedIds(ranking).includes(roomId)) {
            const room = this.rooms.get(roomId);
            this.issues.push(`${user.realName} missing room ${room ? room.name : roomId} in ranked ${descriptor} list`);
          }
        }
        for (const roomId of ranking.rankedIds || []) {
          if (!ranking.unsortedIds?.includes(roomId)) {
            const room = this.rooms.get(roomId);
            this.issues.push(`${user.realName} missing ranked room ${room ? room.name : roomId} in unsorted ${descriptor} list`);
          }
        }
        for (const roomId of getUnrankedIds(ranking)) {
          if (!ranking.unsortedIds || !ranking.unsortedIds.includes(roomId)) {
            const room = this.rooms.get(roomId);
            this.issues.push(`${user.realName} missing unranked room ${room ? room.name : roomId} in unsorted ${descriptor} list`);
          }
        }
        if (ranking.unsortedIds) {
          for (const roomId of ranking.unsortedIds) {
            const room = this.rooms.get(roomId);
            if (!room) {
              this.issues.push(`${user.realName} selected missing room ${roomId}`);
            }
            if (user.affiliatedRoomIds && user.affiliatedRoomIds.includes(roomId)) {
              this.issues.push(`${user.realName} selected affiliated room ${room.name}`);
            }
            if (room && (!room.isFinalist || !room.isFinalist.includes(this.year))) {
              this.issues.push(`${user.realName} selected ineligible room ${room.name}`);
            }
          }
        }
        if (ranking.rankedIds) {
          for (const roomId of ranking.rankedIds) {
            const room = this.rooms.get(roomId);
            if (!room) {
              this.issues.push(`${user.realName} voted on missing room ${roomId}`);
            }
            if (user.affiliatedRoomIds && user.affiliatedRoomIds.includes(roomId)) {
              this.issues.push(`${user.realName} voted on affiliated room ${room.name}`);
            }
            if (room && (!room.isFinalist || !room.isFinalist.includes(this.year))) {
              this.issues.push(`${user.realName} voted on ineligible room ${room.name}`);
            }
          }
        }
        if (ranking.unrankedReasonMap) {
          for (const roomId of getUnrankedIds(ranking)) {
            const room = this.rooms.get(roomId);
            if (ranking.rankedIds?.includes(roomId)) {
              this.issues.push(`${user.realName} has the same room in ranked and unranked lists: ${room.name}`);
            }
            if (!ranking.unrankedReasonMap[roomId]) {
              this.issues.push(`${user.realName} has an unranked room with no reason: ${room.name}`);
            }
            if ([UnrankedReason.DID_NOT_PLAY, UnrankedReason.CONFLICT_OF_INTEREST].includes(ranking.unrankedReasonMap[roomId]?.reason)) {
              this.issues.push(`${user.realName} has an unranked room, ${room.name}, with invalid reason ${ranking.unrankedReasonMap[roomId]?.reason}`);
            }
          }
        }
      }
      if (!ranking.submitted && user.rankingsSubmitted && user.rankingsSubmitted.includes(this.year)) {
        this.issues.push(`${user.realName} improperly showing rankings submitted`);
      }
      if (ranking.submitted && (!user.rankingsSubmitted || !user.rankingsSubmitted.includes(this.year))) {
        this.issues.push(`${user.realName} improperly showing rankings not submitted`);
      }
    }
  }

  async validateQuotes() {
    for (const quote of this.quotes) {
      const user: TerpecaUser = this.users.get(quote.userId);
      if (!user) {
        this.issues.push(`missing user ${quote.userId} from submitted quote`);
        continue;
      }
      const room: TerpecaRoom = this.rooms.get(quote.roomId);
      if (!room) {
        this.issues.push(`missing room ${quote.roomId} from submitted quote`);
        continue;
      }
      if (room.affiliatedUserIds?.includes(user.uid)) {
        this.issues.push(`user ${user.realName} left quote for affiliated room ${room.name}`);
      }
      const ranking: TerpecaRanking = this.regularRankings.get(quote.userId);
      if (!ranking) {
        this.issues.push(`ranking missing for user ${user.realName} with submitted quote`);
      } else if (!ranking.submitted) {
        this.issues.push(`ranking not submitted for user ${user.realName} with submitted quote`);
      }
      if (!ranking.rankedIds?.slice(0, maxQuotesPerYear).includes(quote.roomId)) {
        this.issues.push(`user ${user.realName} has submitted a quote for room ${room.name} not ranked in their top ${maxQuotesPerYear}`);
      }
    }
  }
}
