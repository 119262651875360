import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import app from 'firebase/compat/app';
import { Matrix, abs, divide, index, min, multiply, norm, ones, random, sqrt, subtract } from 'mathjs';
import { Subscription } from 'rxjs';

import { TerpecaRanking, UnrankedReasonEntry, getUnrankedIds } from 'src/app/models/ranking.model';
import {
    CompanyAlgorithm, CompanyCredit, CompanyVersionApproach, CreditMap, Opponent, PairwiseComparison, RankedCompany, RankingMetric,
    ResultsApproach, RoomVersionApproach, TerpecaRankedEntity, VoteWeightingApproach, allCompanyAlgorithms, allCompanyVersionApproaches,
    allResultsApproaches, allRoomVersionApproaches, allVoteWeightingApproaches
} from 'src/app/models/results.model';
import { HorrorLevel, TerpecaCategory, TerpecaRoom, allHorrorLevels } from 'src/app/models/room.model';
import { ApplicationStatus, HorrorPreference, TerpecaUser, allHorrorPreferences } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import { getCommonLocation, getEmails, getLanguages, getLocationString, getNominationCount, isNewRoom } from 'src/app/utils/misc.utils';
import { compareEntitiesByName } from 'src/app/utils/sorting.utils';
import { environment } from 'src/environments/environment';

import { AnalyzeDialogComponent } from '../analyzedialog/analyzedialog.component';

@Component({
  selector: 'app-analyze',
  templateUrl: './analyze.component.html',
  styleUrl: './analyze.component.css'
})
export class AnalyzeComponent implements OnInit, OnDestroy {
  Status = ApplicationStatus;
  Approach = ResultsApproach;
  Category = TerpecaCategory;
  CompanyAlgorithm = CompanyAlgorithm;
  getCommonLocation = getCommonLocation;
  getEmails = getEmails;
  allResultsApproaches = allResultsApproaches;
  allVoteWeightingApproaches = allVoteWeightingApproaches;
  allCompanyAlgorithms = allCompanyAlgorithms;
  allRoomVersionApproaches = allRoomVersionApproaches;
  allCompanyVersionApproaches = allCompanyVersionApproaches;
  allHorrorLevels = allHorrorLevels;
  allHorrorPreferences = allHorrorPreferences;
  getLocationString = getLocationString;
  private rankingSubscription: Subscription;
  unweightedVotePairsMetric: RankingMetric;
  experiencedVotePairsMetric: RankingMetric;
  inexperiencedVotePairsMetric: RankingMetric;
  unweightedRoomPairsMetric: RankingMetric;
  highPercentageRoomPairsMetric: RankingMetric;
  superHighPercentageRoomPairsMetric: RankingMetric;
  yearLoaded: number;
  currentRankingsLoaded = false;
  lastCategoryLoaded: TerpecaCategory;
  lastCategoryAnalyzed: TerpecaCategory;
  lastIrlApproach: ResultsApproach;
  lastOnlineApproach: ResultsApproach;
  lastPValue: number;
  lastVoteWeightingApproach: VoteWeightingApproach;
  lastDoLocalAdjustments: boolean;
  lastInflectionRank: number;
  lastTopOnlineEquivalentRank: number;
  lastTailReferenceCredit: number;
  lastDeepInsideApproach: RoomVersionApproach;
  lastDoorsOfDivergenceApproach: RoomVersionApproach;
  lastPetraApproach: RoomVersionApproach;
  lastSanatoriumApproach: RoomVersionApproach;
  lastDeepInsideCompanyApproach: CompanyVersionApproach;
  lastCompanyAlgorithm: CompanyAlgorithm;
  lastOnlineIrlPairWeight: number;
  lastUserCountryFilter: string;
  lastHorrorPreferenceFilter: HorrorPreference[];
  lastRoomCountryFilter: string;
  lastHorrorLevelFilter: HorrorLevel[];
  loadingInProgress = false;
  analysisInProgress = false;
  log: string[] = ['not started'];
  remapCount: number;
  roomMap: Map<string, TerpecaRoom>;
  roomIdRemap: Map<string, string>;         // maps combined room IDs to their composite IDs
  compositeIdRemap: Map<string, string[]>;  // maps composite IDs back to the original room IDs
  companyMap: Map<string, RankedCompany>;
  indexMap: Map<string, number>;
  userMap: Map<string, TerpecaUser>;
  matrix: Matrix;
  irlRankings: TerpecaRanking[];
  onlineRankings: TerpecaRanking[];
  irlShadowbans: number;
  onlineShadowbans: number;
  entityMap: Map<string, TerpecaRankedEntity>;
  roomColumns: string[] = ['rank', 'room', 'company', 'country', 'tags', 'location', 'email', 'url', 'plays', 'directComps', 'allComps', 'abstains', 'score', 'credit', 'lastRank', 'details'];
  roomDataSource: MatTableDataSource<TerpecaRankedEntity>;
  companyColumns: string[] = ['rank', 'company', 'top25rooms', 'top50rooms', 'top100rooms', 'top200rooms', 'finalists', 'nominees',
                              'credit', 'score', 'email', 'details'];
  companyDataSource: MatTableDataSource<RankedCompany>;
  ALL_DATA_COLUMNS: string[] = ['location', 'email', 'url'];
  SHOW_ALL_DATA_IN_TABLE = false;  // manually change this when we want to show all data in the table
  ALLOW_CAPTURE_RESULTS = false;  // manually change this when we want to capture new results
  anomalousUsers: [TerpecaRanking, number][];
  topRoomScore: number;
  inflectionRoomScore: number;
  topOnlineEquivalentScore: number;
  tailReferenceRoomScore: number;
  bottomRoomScore: number;
  topOnlineRoomScore: number;
  inflectionOnlineRoomScore: number;
  bottomOnlineRoomScore: number;

  DEEP_INSIDE_ID = 'deepinside';
  DOORS_OF_DIVERGENCE_HERESY_ID = 'dod-heresy';
  DOORS_OF_DIVERGENCE_MADNESS_ID = 'dod-madness';
  LA_MINA_ID = 'lamina';
  PETRA_ID = 'petra';
  SANATORIUM_ID = 'sanatorium';

  formGroup: UntypedFormGroup;

  constructor(public auth: AuthService, public settings: SettingsService,
              private db: AngularFirestore, private dialog: MatDialog) { }

  ngOnInit() {
    const defaultYear = environment.currentAwardYear - (this.canShowRoomTitles(this.auth.currentUser,
                                                                               environment.currentAwardYear) ? 0 : 1);
    if (!this.SHOW_ALL_DATA_IN_TABLE) {
      this.roomColumns = this.roomColumns.filter(col => !this.ALL_DATA_COLUMNS.includes(col));
      this.companyColumns = this.companyColumns.filter(col => !this.ALL_DATA_COLUMNS.includes(col));
    }
    this.formGroup = new UntypedFormGroup({
      year: new UntypedFormControl(defaultYear),
      category: new UntypedFormControl(TerpecaCategory.TOP_ROOM),
      irlApproach: new UntypedFormControl(ResultsApproach.DEFAULT_PLUS_SECONDARY_FULL_V3),
      onlineApproach: new UntypedFormControl(ResultsApproach.DEFAULT_PLUS_SECONDARY_SQRT_V2),
      p: new UntypedFormControl(0.05),
      voteWeightingApproach: new UntypedFormControl(VoteWeightingApproach.DEFAULT_DIVIDED_BY_SQRT_N_REINFLATED),
      doLocalAdjustments: new UntypedFormControl(false),
      companyAlgorithm: new UntypedFormControl(CompanyAlgorithm.SIGMOID_FUNCTION_MAPPING),
      inflectionRank: new UntypedFormControl(125),
      topOnlineEquivalentRank: new UntypedFormControl(150),
      tailReferenceCredit: new UntypedFormControl(0.1),
      onlineIrlPairWeight: new UntypedFormControl(1.5),
      userCountryFilter: new UntypedFormControl(''),
      horrorPreferenceFilter: new UntypedFormControl(allHorrorPreferences, Validators.required),
      roomCountryFilter: new UntypedFormControl(''),
      horrorLevelFilter: new UntypedFormControl(allHorrorLevels, Validators.required),
      deepInsideApproach: new UntypedFormControl(RoomVersionApproach.SEPARATE),
      doorsOfDivergenceApproach: new UntypedFormControl(RoomVersionApproach.COMBINED),
      petraApproach: new UntypedFormControl(RoomVersionApproach.BEST),
      sanatoriumApproach: new UntypedFormControl(RoomVersionApproach.BEST),
      deepInsideCompanyApproach: new UntypedFormControl(CompanyVersionApproach.IGNORE_COMBINED_GAMES),
      findAnomalies: new UntypedFormControl(false)
    });
    this.rankingSubscription = this.db.collection<TerpecaRanking>('rankings',
      ref => ref.where('year', '==', defaultYear)
                .where('submitted', '==', true)).valueChanges()
                .subscribe(() => { this.currentRankingsLoaded = false; });
    this.reload();
  }

  ngOnDestroy() {
    this.rankingSubscription.unsubscribe();
  }

  async reload() {
    this.log = [];
    this.formGroup.disable();
    this.loadingInProgress = true;
    this.yearLoaded = null;
    const yearToLoad = this.formGroup.value.year;
    this.roomDataSource = null;
    this.companyDataSource = null;
    this.remapCount = 0;
    await this.loadUsers(yearToLoad);
    await this.loadRooms(yearToLoad);
    await this.loadRankings(yearToLoad);
    this.yearLoaded = yearToLoad;
    this.log.push(`loaded ${yearToLoad} data: ${this.roomMap.size} rooms (${this.remapCount} combined), ` +
                  `${this.irlRankings.length} IRL rankings (${this.irlShadowbans} shadowbans)` +
                  (this.onlineRankings.length ?
                  `, ${this.onlineRankings.length} online rankings (${this.onlineShadowbans} shadowbans)` : ''));
    this.loadingInProgress = false;
    this.formGroup.enable();
    this.formGroup.markAsDirty();
  }

  async loadUsers(year: number) {
    this.userMap = new Map<string, TerpecaUser>();
    await this.db.collection<TerpecaUser>('users').ref
        .where('rankingsSubmitted', 'array-contains', year).get()
        .then((snapshot: QuerySnapshot<TerpecaUser>) => {
          for (const doc of snapshot.docs) {
            const user: TerpecaUser = doc.data();
            this.userMap.set(user.uid, user);
          }
        });
  }

  async loadRooms(year: number) {
    this.roomMap = new Map<string, TerpecaRoom>();
    this.roomIdRemap = new Map<string, string>();
    this.compositeIdRemap = new Map<string, string[]>();
    this.entityMap = new Map<string, TerpecaRankedEntity>();
    this.companyMap = new Map<string, RankedCompany>();
    const categoryToLoad = this.formGroup.value.category;
    await this.db.collection<TerpecaRoom>('rooms').ref
    .where('isNominee', 'array-contains', year).get()
    .then((snapshot: QuerySnapshot<TerpecaRoom>) => {
      for (const doc of snapshot.docs) {
        const room: TerpecaRoom = doc.data();
        // 2018 was weird in that we did both room and company voting using the exact same process, so we handle it
        // here as a special case by simply treating company rankings in 2018 as if they were IRL room rankings and
        // we require a reload when changing categories.
        if (year === 2018 && room.category !== categoryToLoad) {
          continue;
        }
        if (!this.matchesCountryFilter(this.formGroup.value.roomCountryFilter, room.country)) {
          continue;
        }
        if (!this.matchesHorrorLevelFilter(this.formGroup.value.horrorLevelFilter, room.horrorLevel)) {
          continue;
        }
        room.docId = doc.id;
        const compositeId = this.getCompositeId(room);
        const versionName = this.getVersionName(room);
        if (compositeId !== room.docId) {
          const roomClone = Object.assign({ }, room);
          this.roomMap.set(room.docId, roomClone);
          this.roomIdRemap.set(room.docId, compositeId);
          const idList = this.compositeIdRemap.get(compositeId) || [];
          if (!idList.includes(room.docId)) {
            idList.push(room.docId);
            this.compositeIdRemap.set(compositeId, idList);
          }
          this.updateCompositeRoom(room);
          this.roomMap.set(compositeId, room);
          this.remapCount++;
        } else {
          this.roomMap.set(room.docId, room);
        }
        if (room.isFinalist.includes(year)) {
          if (!this.entityMap.has(compositeId)) {
            this.entityMap.set(compositeId, this.newEntity(room, year));
          }
        }
        const companyNames = this.getCompanyNames(room, year);
        for (const companyName of companyNames) {
          if (!this.companyMap.has(companyName)) {
            this.companyMap.set(companyName, this.newCompany(companyName));
          }
          const company = this.companyMap.get(companyName);
          const hasVersion = versionName ? this.containsVersion(company.rooms, versionName) : false;
          company.rooms.push(room);
          if (!company.nominees.includes(compositeId) && !hasVersion) {
            company.nominees.push(compositeId);
          }
          if (room.isFinalist.includes(year)) {
            if (!company.finalists.includes(compositeId) && !hasVersion) {
              company.finalists.push(compositeId);
            }
          }
        }
      }
      this.lastCategoryLoaded = categoryToLoad;
      this.lastUserCountryFilter = this.formGroup.value.userCountryFilter;
      this.lastHorrorPreferenceFilter = this.formGroup.value.horrorPreferenceFilter;
      this.lastRoomCountryFilter = this.formGroup.value.roomCountryFilter;
      this.lastHorrorLevelFilter = this.formGroup.value.horrorLevelFilter;
      this.lastDeepInsideApproach = this.formGroup.value.deepInsideApproach;
      this.lastDoorsOfDivergenceApproach = this.formGroup.value.doorsOfDivergenceApproach;
      this.lastPetraApproach = this.formGroup.value.petraApproach;
      this.lastSanatoriumApproach = this.formGroup.value.sanatoriumApproach;
      this.lastDeepInsideCompanyApproach = this.formGroup.value.deepInsideCompanyApproach;
      this.lastCompanyAlgorithm = this.formGroup.value.companyAlgorithm;
      this.lastOnlineIrlPairWeight = this.formGroup.value.onlineIrlPairWeight;
    });
  }

  canLoad() {
    if (this.loadingInProgress || !this.formGroup.valid) {
      return false;
    }
    if (this.formGroup.value.year !== this.yearLoaded ||
        this.formGroup.value.userCountryFilter !== this.lastUserCountryFilter ||
        this.formGroup.value.horrorPreferenceFilter !== this.lastHorrorPreferenceFilter ||
        this.formGroup.value.roomCountryFilter !== this.lastRoomCountryFilter ||
        this.formGroup.value.horrorLevelFilter !== this.lastHorrorLevelFilter ||
        ([2018, 2020].includes(this.yearLoaded) && this.formGroup.value.category !== this.lastCategoryLoaded) ||
        (this.formGroup.value.year > 2018 && (
         this.formGroup.value.deepInsideApproach !== this.lastDeepInsideApproach ||
         this.formGroup.value.doorsOfDivergenceApproach !== this.lastDoorsOfDivergenceApproach ||
         this.formGroup.value.petraApproach !== this.lastPetraApproach ||
         this.formGroup.value.sanatoriumApproach !== this.lastSanatoriumApproach ||
         this.formGroup.value.deepInsideCompanyApproach !== this.lastDeepInsideCompanyApproach ||
         this.formGroup.value.onlineIrlPairWeight !== this.lastOnlineIrlPairWeight))) {
      return true;
    }
    if (this.yearLoaded === environment.currentAwardYear) {
      return !this.currentRankingsLoaded;
    }
    return false;
  }

  async loadRankings(year: number) {
    this.irlShadowbans = 0;
    this.onlineShadowbans = 0;
    await this.db.collection<TerpecaRanking>('rankings').ref
    .where('year', '==', year)
    .where('submitted', '==', true).get()
    .then((snapshot: QuerySnapshot<TerpecaRanking>) => {
      const irlRankings: TerpecaRanking[] = [];
      const onlineRankings: TerpecaRanking[] = [];
      for (const doc of snapshot.docs) {
        const ranking: TerpecaRanking = doc.data();
        if (year === 2018 && ranking.category !== this.formGroup.value.category) {
          continue;
        }
        if (!this.matchesCountryFilter(this.formGroup.value.userCountryFilter, this.userMap.get(ranking.userId)?.country)) {
          continue;
        }
        if (!this.matchesHorrorPreferenceFilter(
          this.formGroup.value.horrorPreferenceFilter,
          this.userMap.get(ranking.userId)?.horrorPreference)) {
          continue;
        }
        ranking.docId = doc.id;
        if ([TerpecaCategory.TOP_ROOM, TerpecaCategory.TOP_COMPANY].includes(ranking.category)) {
          if (ranking.shadowbanned) {
            ++this.irlShadowbans;
          }
          irlRankings.push(ranking);
        } else if (ranking.category === TerpecaCategory.TOP_ONLINE_ROOM) {
          if (ranking.shadowbanned) {
            ++this.onlineShadowbans;
          }
          onlineRankings.push(ranking);
        }
      }
      this.irlRankings = irlRankings;
      this.onlineRankings = onlineRankings;
      if (year === environment.currentAwardYear) {
        this.currentRankingsLoaded = true;
      }
    });
  }

  canAnalyze() {
    if (!this.formGroup.valid) {
      return false;
    }
    if (this.formGroup.value.year !== this.yearLoaded ||
        this.formGroup.value.userCountryFilter !== this.lastUserCountryFilter ||
        this.formGroup.value.horrorPreferenceFilter !== this.lastHorrorPreferenceFilter ||
        this.formGroup.value.roomCountryFilter !== this.lastRoomCountryFilter ||
        this.formGroup.value.horrorLevelFilter !== this.lastHorrorLevelFilter ||
        ([2018, 2020].includes(this.yearLoaded) && this.formGroup.value.category !== this.lastCategoryLoaded) ||
        (this.formGroup.value.year > 2018 && (
         this.formGroup.value.deepInsideApproach !== this.lastDeepInsideApproach ||
         this.formGroup.value.doorsOfDivergenceApproach !== this.lastDoorsOfDivergenceApproach ||
         this.formGroup.value.petraApproach !== this.lastPetraApproach ||
         this.formGroup.value.sanatoriumApproach !== this.lastSanatoriumApproach ||
         this.formGroup.value.deepInsideCompanyApproach !== this.lastDeepInsideCompanyApproach ||
         this.formGroup.value.onlineIrlPairWeight !== this.lastOnlineIrlPairWeight))) {
      return false;
    }
    return !this.analysisInProgress && !this.loadingInProgress && this.yearLoaded === this.formGroup.value.year && this.formGroup.dirty;
  }

  async analyze() {
    this.formGroup.disable();
    this.analysisInProgress = true;
    await this.processRankings();
    await this.runEigenvectorAnalysis();
    this.analysisInProgress = false;
    this.formGroup.enable();
    this.formGroup.markAsPristine();
  }

  canShowResults() {
    return this.canShowRoomResults() || this.canShowCompanyResults() || this.canShowAnomalousUsers();
  }

  canShowRoomResults() {
    return !this.loadingInProgress && !this.analysisInProgress && this.yearLoaded && this.roomDataSource;
  }

  canShowCompanyResults() {
    return !this.loadingInProgress && !this.analysisInProgress && this.yearLoaded && this.companyDataSource;
  }

  canShowAnomalousUsers() {
    return !this.loadingInProgress && !this.analysisInProgress && this.yearLoaded && this.anomalousUsers?.length;
  }

  canCaptureResults() {
    return this.ALLOW_CAPTURE_RESULTS && this.auth.currentUser.isOwner && this.canShowRoomResults() &&
        this.formGroup.pristine && !this.lastUserCountryFilter && this.lastHorrorPreferenceFilter === allHorrorPreferences &&
        !this.lastRoomCountryFilter && this.lastHorrorLevelFilter === allHorrorLevels &&
        (this.yearLoaded < environment.currentAwardYear || this.settings.isPastVotingDeadline());
  }

  async captureResults() {
    if (!this.canCaptureResults()) {
      return;
    }
    const batch = this.db.firestore.batch();
    for (const entity of this.roomDataSource.data) {
      let roomIdsToUpdate: string[];
      if (this.compositeIdRemap.has(entity.docId)) {
        // We're dealing with a composite ID, so we need to look up which actual room IDs we want to update with
        // the results data -- all constituent rooms for a given composite room will be given identical data.
        roomIdsToUpdate = this.compositeIdRemap.get(entity.docId);
      } else {
        roomIdsToUpdate = [entity.docId];
      }
      for (const roomId of roomIdsToUpdate) {
        const room = this.roomMap.get(roomId);
        if (!room || room.docId !== roomId) {
          // This shouldn't happen, but just in case, we bail to ensure we don't write bad data.
          console.log(`data mismatch: ${room?.docId} vs ${roomId}!`);
          return;
        }
        const entityClone = Object.assign({ }, entity);
        delete entityClone.resultsMap;  // We don't need to store all the pairwise comparisons.
        const resultsData = room.resultsData || {};
        resultsData[this.yearLoaded] = entityClone;
        batch.update(this.db.collection<TerpecaRoom>('rooms').doc(room.docId).ref, {
          isWinner: <number><unknown>(entity.winner ?
              app.firestore.FieldValue.arrayUnion(this.yearLoaded) :
              app.firestore.FieldValue.arrayRemove(this.yearLoaded)),
          resultsData: resultsData
        });
      }
    }
    await batch.commit();
    await this.reload();
    await this.analyze();
  }

  hadDirectCompanyVoting(year: number) {
    return year === 2018;
  }

  hadOnlineRoomVoting(year: number) {
    return [2020, 2021].includes(year);
  }

  wasDeepInsideAFinalist(year: number) {
    return year >= 2022;
  }

  wasDoorsOfDivergenceAFinalist(year: number) {
    return year === 2023;
  }

  wasPetraAFinalist(year: number) {
    return year >= 2022;
  }

  wasSanatoriumAFinalist(year: number) {
    return year >= 2019;
  }

  isWinnerByRank(rank: number, category: TerpecaCategory, year: number) {
    return rank <= this.settings.numWinners(category, year);
  }

  canShowRoomTitles(user: TerpecaUser, year: number) {
    return (user.isOwner && user.rankingsSubmitted && user.rankingsSubmitted.includes(year))
      || !this.settings.isCurrentYear(year)
      || (user.hasResultsAccess && this.settings.isPastVotingDeadline());
  }

  showNewLogo(user: TerpecaUser, roomId: string) {
    return this.canShowRoomTitles(user, this.yearLoaded) && isNewRoom(this.roomMap.get(roomId), this.yearLoaded);
  }

  horrorLevel(roomId: string) {
    const room = this.roomMap.get(roomId);
    return room.horrorLevel || HorrorLevel.UNKNOWN;
  }

  languages(roomId: string) {
    const room = this.roomMap.get(roomId);
    return getLanguages(room);
  }

  lastRank(roomId: string) {
    const room = this.roomMap.get(roomId);
    const lastYear = this.yearLoaded - 1;
    if (room.isFinalist?.includes(lastYear)) {
      if (!room.resultsData || !room.resultsData[lastYear]) {
        return 'unknown';
      }
      const entity: TerpecaRankedEntity = room.resultsData[lastYear];
      return entity.unranked ? 'NR' : entity.rank;
    }
    if (room.isNominee?.includes(lastYear)) {
      return 'nominee';
    }
    return '';
  }

  showDetails(entity: TerpecaRankedEntity) {
    const opponents = [];
    this.entityMap.forEach((opponent: TerpecaRankedEntity, _key: string) => {
      if (entity.category === opponent.category) {
        opponents.push(<Opponent>{
          rank: opponent.rank,
          unranked: opponent.unranked,
          docId: opponent.docId,
          name: this.getName(opponent.docId),
          record: entity.resultsMap[opponent.docId]
        });
      }
    });
    opponents.sort((a: Opponent, b: Opponent) => a.rank - b.rank);
    const data: any = {
      entity,
      name: this.getName(entity.docId),
      opponents,
      user: this.auth.currentUser,
      entityMap: this.entityMap,
      roomIdRemap: this.roomIdRemap,
      roomMap: this.roomMap,
      showUnweightedVotes: this.formGroup.value.voteWeightingApproach !== VoteWeightingApproach.DEFAULT,
      canShowRoomTitles: this.canShowRoomTitles(this.auth.currentUser, this.yearLoaded)
    };
    if (data.user.isOwner) {
      data.rankings = entity.category === TerpecaCategory.TOP_ROOM ? this.irlRankings : this.onlineRankings;
      data.userMap = this.userMap;
    }
    this.dialog.open(AnalyzeDialogComponent, { data });
  }

  showAnonymizedRankings(ranking: TerpecaRanking) {
    const data: any = {
      user: this.auth.currentUser,
      entityMap: this.entityMap,
      roomIdRemap: this.roomIdRemap,
      roomMap: this.roomMap,
      showUnweightedVotes: this.formGroup.value.voteWeightingApproach !== VoteWeightingApproach.DEFAULT,
      canShowRoomTitles: this.canShowRoomTitles(this.auth.currentUser, this.yearLoaded)
    };
    if (data.user.isOwner) {
      data.rankings = [ranking];
      data.userMap = this.userMap;
    }
    this.dialog.open(AnalyzeDialogComponent, { data });
  }

  showCompanyDetails(company: RankedCompany) {
    if (this.formGroup.value.companyAlgorithm === CompanyAlgorithm.SIGMOID_FUNCTION_MAPPING) {
      company.rooms.sort(this.compareByRawCredit(company.creditMap));
    } else {
      company.rooms.sort(this.compareByCategoryAndRank(this.entityMap));
    }
    const data: any = {
      entity: company,
      year: this.yearLoaded,
      name: company.name,
      rooms: company.rooms,
      creditMap: company.creditMap,
      user: this.auth.currentUser,
      entityMap: this.entityMap,
      roomIdRemap: this.roomIdRemap,
      roomMap: this.roomMap,
      showUnweightedVotes: this.formGroup.value.voteWeightingApproach !== VoteWeightingApproach.DEFAULT,
      canShowRoomTitles: this.canShowRoomTitles(this.auth.currentUser, this.yearLoaded)
    };
    this.dialog.open(AnalyzeDialogComponent, { data });
  }

  getName(entityId: string) {
    const entity: TerpecaRoom = this.roomMap.get(entityId);
    if (entity.category === TerpecaCategory.TOP_COMPANY) {
      return entity.company;
    }
    return entity.englishName || entity.name;
  }

  async processRankings() {
    this.entityMap.forEach((value: TerpecaRankedEntity, _key: string) => {
      this.resetEntity(value);
    });
    this.companyMap.forEach((value: RankedCompany, _key: string) => {
      this.resetCompany(value);
    });
    for (const ranking of this.irlRankings) {
      this.processRanking(ranking);
    }
    for (const ranking of this.onlineRankings) {
      this.processRanking(ranking);
    }
  }

  async processRanking(ranking: TerpecaRanking) {
    if (ranking.shadowbanned) {
      return;
    }
    if (!ranking.unsortedIds) {
      this.log.push(`ranking missing IDs: ${JSON.stringify(ranking)}`);
      return;
    }
    const versionCountsMap = new Map<string, number>();
    for (const id of ranking.unsortedIds) {
      const remappedId = this.getRemappedId(id);
      if (!remappedId) {
        continue;
      }
      if (!versionCountsMap.has(remappedId)) {
        this.registerPlay(this.entityMap.get(remappedId));
        versionCountsMap.set(remappedId, 1);
      } else {
        versionCountsMap.set(remappedId, versionCountsMap.get(remappedId) + 1);
      }
    }
    if (ranking.rankedIds.length > 1) {
      for (let winnerIndex = 0; winnerIndex < ranking.rankedIds.length - 1; ++winnerIndex) {
        for (let loserIndex = winnerIndex + 1; loserIndex < ranking.rankedIds.length; ++loserIndex) {
          const winnerId = this.getRemappedId(ranking.rankedIds[winnerIndex]);
          const loserId = this.getRemappedId(ranking.rankedIds[loserIndex]);
          if (winnerId && loserId && winnerId !== loserId) {
            if (ranking.year <= 2023) {
              // This preserves a minor bug in this calculation that existed until 2023, so that those years remain consistent
              // with our published results.
              this.registerComp(this.entityMap.get(winnerId), loserId, loserIndex - winnerIndex, versionCountsMap.get(winnerId),
                                ranking.rankedIds.length, ranking.userId);
              this.registerComp(this.entityMap.get(loserId), winnerId, winnerIndex - loserIndex, versionCountsMap.get(loserId),
                                ranking.rankedIds.length, ranking.userId);
            } else {
              const scale = versionCountsMap.get(winnerId) * versionCountsMap.get(loserId);
              this.registerComp(this.entityMap.get(winnerId), loserId, loserIndex - winnerIndex, scale, ranking.rankedIds.length,
                                ranking.userId);
              this.registerComp(this.entityMap.get(loserId), winnerId, winnerIndex - loserIndex, scale, ranking.rankedIds.length,
                                ranking.userId);
            }
          }
        }
      }
    }
    for (const unrankedId of getUnrankedIds(ranking)) {
      const remappedId = this.getRemappedId(unrankedId);
      if (!remappedId) {
        continue;
      }
      this.registerAbstain(this.entityMap.get(remappedId), ranking.unrankedReasonMap[unrankedId]);
    }
  }

  getRemappedId(id: string) {
    if (!this.matchesCountryFilter(this.formGroup.value.roomCountryFilter, this.roomMap.get(id)?.country)) {
      return null;
    }
    if (!this.matchesHorrorLevelFilter(this.formGroup.value.horrorLevelFilter, this.roomMap.get(id)?.horrorLevel)) {
      return null;
    }
    if (this.roomIdRemap.has(id)) {
      return this.roomIdRemap.get(id);
    }
    return id;
  }

  // Adapted from https://en.wikipedia.org/wiki/Power_iteration
  powerIteration(A: Matrix) {  // eslint-disable-line @typescript-eslint/naming-convention
    const MAX_SIMULATIONS = 200;
    const THRESHOLD = 1e-15;
    // start with a random vector
    let bK = <Matrix><unknown>random([A.size()[0]]);
    let diff: number;
    for (let i = 0; i < MAX_SIMULATIONS; ++i) {
      const prevBk = bK;
      // calculate the matrix-by-vector product Ab
      const bK1 = multiply(A, bK);
      // calculate the norm
      const bK1norm = norm(bK1, 'fro');
      // renormalize the vector
      bK = <Matrix>divide(bK1, bK1norm);
      diff = <number>norm(<Matrix>subtract(prevBk, bK), 'fro');
      if (diff < THRESHOLD) {
        break;
      }
    }
    if (diff >= THRESHOLD) {
      this.log.push(`❌ failed to converge, results may be non-deterministic.`);
    }
    return bK;
  }

  // The basic approach is the "direct method" described in
  //   "The Perron-Frobenius Theorem and the Ranking of Football Teams", James P. Keener,
  //   SIAM Review, Vol. 35, No. 1. (Mar., 1993), pp. 80-93.
  //   https://umdrive.memphis.edu/ccrousse/public/MATH%207375/PERRON.pdf
  // It uses concepts based on:
  //   https://en.wikipedia.org/wiki/Eigenvector_centrality
  //   https://en.wikipedia.org/wiki/Perron-Frobenius_theorem
  //   https://en.wikipedia.org/wiki/Power_iteration
  async runEigenvectorAnalysis() {
    this.indexMap = new Map<string, number>();
    let i = 0;
    this.entityMap.forEach((value: TerpecaRankedEntity, key: string) => {
      this.indexMap.set(key, i++);
    });
    this.matrix = <Matrix>ones(i, i);
    this.entityMap.forEach((entity: TerpecaRankedEntity, entityId: string) => {
      this.entityMap.forEach((opponent: TerpecaRankedEntity, opponentId: string) => {
        const matrixIndex = index(this.indexMap.get(entityId), this.indexMap.get(opponentId));
        if (entityId !== opponentId && entity.category === opponent.category) {
          const record = entity.resultsMap[opponentId];
          const approach: ResultsApproach = [TerpecaCategory.TOP_ROOM, TerpecaCategory.TOP_COMPANY].includes(entity.category) ?
                                            this.formGroup.value.irlApproach :
                                            this.formGroup.value.onlineApproach;
          if (approach !== ResultsApproach.DEFAULT) {
            this.entityMap.forEach((pivot: TerpecaRankedEntity, pivotId: string) => {
              if (entityId !== opponentId && pivotId !== entityId && pivotId !== opponentId &&
                  entity.resultsMap[pivotId].directComps && opponent.resultsMap[pivotId].directComps) {
                let entityVsPivotConfidence = entity.resultsMap[pivotId].directConfidence;
                let opponentVsPivotConfidence = opponent.resultsMap[pivotId].directConfidence;
                if (approach === ResultsApproach.DEFAULT_PLUS_SECONDARY_SQRT_V1) {
                  // In V1, we directly use the confidence of the pivot as secondary wins and losses.
                  record.secondaryWins += entityVsPivotConfidence;
                  record.secondaryLosses += opponentVsPivotConfidence;
                  record.secondaryPivots++;
                  record.secondaryPivotsScaled++;
                } else if (approach === ResultsApproach.DEFAULT_PLUS_SECONDARY_SQRT_V2 ||
                           approach === ResultsApproach.DEFAULT_PLUS_SECONDARY_FULL_V2 ||
                           approach === ResultsApproach.DEFAULT_PLUS_SECONDARY_FULL_V3) {
                  // In V2 and beyond, we scale the contribution of each pivot based on the specifics of the pivot and its relationship
                  // with the entities being compared. More on that below.
                  const entityVsPivotWins = entity.resultsMap[pivotId].directWins;
                  const entityVsPivotLosses = entity.resultsMap[pivotId].directLosses;
                  const opponentVsPivotWins = opponent.resultsMap[pivotId].directWins;
                  const opponentVsPivotLosses = opponent.resultsMap[pivotId].directLosses;
                  entityVsPivotConfidence = entity.resultsMap[pivotId].directConfidence;
                  opponentVsPivotConfidence = opponent.resultsMap[pivotId].directConfidence;
                  // In v3, we use a pivot only if the data suggests the pivot is in the middle. This prevents us from amplifying noise.
                  if (approach !== ResultsApproach.DEFAULT_PLUS_SECONDARY_FULL_V3 ||
                      (entityVsPivotConfidence > 0.5 && opponentVsPivotConfidence < 0.5) ||
                      (entityVsPivotConfidence < 0.5 && opponentVsPivotConfidence > 0.5)) {
                    // We determine the strength of the pivot by the number of secondary comps that suggest the pivot is in the middle.
                    // This prevents pivots that are unanimously above or below a given pair of compared rooms from having any effect.
                    const pivotStrength = <number>min(entityVsPivotWins, opponentVsPivotLosses) +
                                          <number>min(entityVsPivotLosses, opponentVsPivotWins);
                    if (pivotStrength > 0) {
                      // We reduce the impact of any one (non-fractional) pivot by square-rooting its strength. This helps limit regional
                      // bias from areas with a disproportionately large numbers of voters.
                      const scale = Math.min(pivotStrength, <number>sqrt(pivotStrength));
                      const likelihood = entityVsPivotConfidence / (entityVsPivotConfidence + opponentVsPivotConfidence);
                      record.secondaryWins += likelihood * scale;
                      record.secondaryLosses += (1 - likelihood) * scale;
                      record.secondaryPivots++;
                      record.secondaryPivotsScaled += scale;
                    }
                  }
                }
              }
            });
          }
          if (record.secondaryPivotsScaled > 0) {
            let scale = 1;
            switch (approach) {
              case ResultsApproach.DEFAULT_PLUS_SECONDARY_SQRT_V1:
              case ResultsApproach.DEFAULT_PLUS_SECONDARY_SQRT_V2:
                // These approaches limit the number of secondary wins and losses by square rooting by the number of pivots.
                scale = Math.max(1, <number>sqrt(record.secondaryPivotsScaled));
                break;
              case ResultsApproach.DEFAULT_PLUS_SECONDARY_FULL_V2:
              case ResultsApproach.DEFAULT_PLUS_SECONDARY_FULL_V3:
                // These approaches allow as many secondary wins and losses as there are usable pivots, to better fill in gaps in coverage.
                scale = Math.max(1, (record.secondaryPivotsScaled / record.secondaryPivots));
                break;
            }
            record.secondaryWins /= scale;
            record.secondaryLosses /= scale;
          }
          record.secondaryComps = record.secondaryWins + record.secondaryLosses;
          if (approach === ResultsApproach.DEFAULT_PLUS_SECONDARY_FULL_V3) {
            // Secondary "wins" and "losses" are derived above from head-to-head _confidence_ values, scaled in various ways,
            // and should be considered as confidence-scaled values. However, code that uses them (below) treats them as raw
            // values and adds them to primary wins and losses before re-computing confidence values. To patch that, in V3
            // we _reverse_ the confidence calculation to make the previously computed values more like raw values.
            const estimatedSecondaryConfidence = this.winFraction(record.secondaryWins, record.secondaryLosses);
            const estimatedSecondaryWins = this.winsNeededForConfidence(estimatedSecondaryConfidence, record.secondaryComps);
            const estimatedSecondaryLosses = record.secondaryComps - estimatedSecondaryWins;
            record.secondaryWins = estimatedSecondaryWins;
            record.secondaryLosses = estimatedSecondaryLosses;
          }
          record.secondaryWinFraction = this.winFraction(record.secondaryWins, record.secondaryLosses);
          record.secondaryConfidence = this.confidence(record.secondaryWins, record.secondaryLosses);
          record.winFraction = this.winFraction(record.directWins + record.secondaryWins, record.directLosses + record.secondaryLosses);
          record.confidence = this.confidence(record.directWins + record.secondaryWins, record.directLosses + record.secondaryLosses);
          this.matrix.subset(matrixIndex, record.confidence);
        } else {
          this.matrix.subset(matrixIndex, 0.5);
        }
      });
    });
    const eigenvector: Matrix = this.powerIteration(this.matrix);
    const entityList: TerpecaRankedEntity[] = [];
    i = 0;
    this.entityMap.forEach((value: TerpecaRankedEntity, _key: string) => {
      value.strength = <number><any>eigenvector.subset(index(i));
      ++i;
    });
    i = 0;
    this.entityMap.forEach((value: TerpecaRankedEntity, _key: string) => {
      value.score = this.weightedScore(value);
      entityList.push(value);
      ++i;
    });
    entityList.sort(this.compareByScore);
    if (this.formGroup.value.doLocalAdjustments) {
      this.doLocalAdjustments(entityList);
    }
    let rank = 1;
    let onlinerank = 1;
    const versionedRooms = [];
    this.topRoomScore = 0;
    this.inflectionRoomScore = 0;
    this.topOnlineEquivalentScore = 0;
    this.tailReferenceRoomScore = 0;
    this.bottomRoomScore = 0;
    this.topOnlineRoomScore = 0;
    this.inflectionOnlineRoomScore = 0;
    this.bottomOnlineRoomScore = 0;
    for (const entity of entityList) {
      const versionName = this.getVersionName(entity);
      if ([TerpecaCategory.TOP_ROOM, TerpecaCategory.TOP_COMPANY].includes(entity.category)) {
        if (versionName) {
          if (!this.containsVersion(versionedRooms, versionName)) {
            entity.rank = rank++;
            versionedRooms.push(entity);
          } else {
            entity.rank = rank;
            entity.unranked = true;
          }
        } else {
          entity.rank = rank++;
        }
        if (entity.rank === 1) {
          this.topRoomScore = entity.score;
        } else if (entity.rank === this.formGroup.value.inflectionRank) {
          this.inflectionRoomScore = entity.score;
        } else if (entity.rank === this.formGroup.value.inflectionRank * 2) {
          this.tailReferenceRoomScore = entity.score;
        }
        if (entity.rank === this.formGroup.value.topOnlineEquivalentRank) {
          this.topOnlineEquivalentScore = entity.score;
        }
        this.bottomRoomScore = entity.score;
      }
      if (entity.category === TerpecaCategory.TOP_ONLINE_ROOM) {
        entity.rank = onlinerank++;
        if (entity.rank === 1) {
          this.topOnlineRoomScore = entity.score;
        } else if (entity.rank === this.formGroup.value.inflectionRank / 5) {
          this.inflectionOnlineRoomScore = entity.score;
        }
        this.bottomOnlineRoomScore = entity.score;
      }
      entity.winner = this.isWinnerByRank(entity.rank, entity.category, this.yearLoaded);
    }
    for (const entity of entityList) {
      if (this.formGroup.value.companyAlgorithm === CompanyAlgorithm.SIGMOID_FUNCTION_MAPPING) {
        entity.companyCredit = this.companyCredit(entity.docId);
      }
      if (!entity.unranked) {
        if (this.formGroup.value.category === TerpecaCategory.TOP_COMPANY) {
          const room = this.roomMap.get(entity.docId);
          const companyNames = this.getCompanyNames(room, this.yearLoaded);
          for (const companyName of companyNames) {
            const company = this.companyMap.get(companyName);
            if (room.category === TerpecaCategory.TOP_ROOM) {
              if (entity.rank <= 25) {
                company.top25rooms.push(entity.docId);
              }
              if (entity.rank <= 50) {
                company.top50rooms.push(entity.docId);
              }
              if (entity.rank <= 100) {
                company.top100rooms.push(entity.docId);
              }
              if (entity.rank <= 200) {
                company.top200rooms.push(entity.docId);
              }
            }
            if (room.category === TerpecaCategory.TOP_ONLINE_ROOM) {
              if (entity.rank <= 10) {
                company.top10onlinerooms.push(entity.docId);
              }
              if (entity.rank <= 20) {
                company.top20onlinerooms.push(entity.docId);
              }
            }
            company.totalScore += entity.score;
          }
        }
      }
    }
    // Generate a score for this ranking based on how well it represents the data.
    this.unweightedVotePairsMetric = this.newMetric();
    this.experiencedVotePairsMetric = this.newMetric();
    this.inexperiencedVotePairsMetric = this.newMetric();
    this.unweightedRoomPairsMetric = this.newMetric();
    this.highPercentageRoomPairsMetric = this.newMetric();
    this.superHighPercentageRoomPairsMetric = this.newMetric();
    for (const e1 of entityList) {
      for (const e2 of entityList) {
        if (e1.docId !== e2.docId && e1.rank && e2.rank && e1.category === e2.category) {
          const record = e1.resultsMap[e2.docId];
          if (e1.rank < e2.rank) {
            this.unweightedVotePairsMetric.pairsPredicted += record.unweightedWins;
            if (record.unweightedConfidence > 0.5) {
              this.unweightedRoomPairsMetric.pairsPredicted += 1;
            }
            if (record.unweightedComps >= 4) {
              if (record.unweightedWinFraction > 0.6) {
                this.highPercentageRoomPairsMetric.pairsPredicted += 1;
              }
              if (record.unweightedWinFraction > 0.8) {
                this.superHighPercentageRoomPairsMetric.pairsPredicted += 1;
              }
            }
          } else if (e1.rank > e2.rank) {
            this.unweightedVotePairsMetric.pairsViolated += record.unweightedWins;
            if (record.unweightedConfidence > 0.5) {
              this.unweightedRoomPairsMetric.pairsViolated += 1;
            }
            if (record.unweightedComps >= 4) {
              if (record.unweightedWinFraction > 0.6) {
                this.highPercentageRoomPairsMetric.pairsViolated += 1;
              }
              if (record.unweightedWinFraction > 0.8) {
                this.superHighPercentageRoomPairsMetric.pairsViolated += 1;
              }
            }
          }
        }
      }
    }
    this.anomalousUsers = [];
    for (const ranking of this.irlRankings) {
      let pairsPredicted = 0;
      let pairsViolated = 0;
      for (let ri = 0; ri < ranking.rankedIds.length; ++ri) {
        const ranki = this.entityMap.get(ranking.rankedIds[ri])?.rank;
        for (let rj = ri + 1; rj < ranking.rankedIds.length; ++rj) {
          const rankj = this.entityMap.get(ranking.rankedIds[rj])?.rank;
          if (ranki && rankj) {
            if (ranki < rankj) {
              ++pairsPredicted;
              if (ranking.rankedIds.length > 50) {
                this.experiencedVotePairsMetric.pairsPredicted++;
              } else {
                this.inexperiencedVotePairsMetric.pairsPredicted++;
              }
            } else {
              ++pairsViolated;
              if (ranking.rankedIds.length > 50) {
                this.experiencedVotePairsMetric.pairsViolated++;
              } else {
                this.inexperiencedVotePairsMetric.pairsViolated++;
              }
            }
          }
        }
      }
      if (this.auth.currentUser.isOwner && this.formGroup.value.findAnomalies) {
        const anomalyFraction = pairsViolated > 0 ? pairsViolated / (pairsPredicted + pairsViolated) : 0;
        if (!environment.production || (anomalyFraction > 0.25 && ranking.rankedIds.length > 10) || ranking.shadowbanned) {
          this.anomalousUsers.push([ranking, anomalyFraction]);
        }
      }
    }
    this.anomalousUsers.sort((a: [TerpecaRanking, number], b: [TerpecaRanking, number]) => {
      if (a[1] !== b[1]) {
        return b[1] - a[1];
      }
      return b[0].rankedIds.length - a[0].rankedIds.length;
    });
    if (this.formGroup.value.category === TerpecaCategory.TOP_ROOM ||
        this.formGroup.value.category === TerpecaCategory.TOP_ONLINE_ROOM ||
        this.yearLoaded === 2018) {
      this.roomDataSource = new MatTableDataSource(entityList.filter(r => r.category === this.formGroup.value.category));
      this.companyDataSource = null;
    } else {
      const companyList: RankedCompany[] = [];
      this.companyMap.forEach((value: RankedCompany, _key: string) => {
        switch (this.formGroup.value.companyAlgorithm) {
          case CompanyAlgorithm.DISCRETE_BUCKETS:
            if (value.finalists.length >= 1 && value.nominees.length >= 2) {
              value.score = 0;
              // We don't need to calculate effective bucket size for 25 and 50, since those are only IRL rooms.
              if (value.top25rooms.length > 1) {
                value.score += 100 + 25 * (value.top25rooms.length - 1);
              }
              if (value.top50rooms.length > 1) {
                value.score += 100 + 10 * (value.top50rooms.length - 1);
              }
              const top100BucketSize = this.effectiveBucketSize(value, [].concat(value.top100rooms, value.top10onlinerooms));
              if (top100BucketSize > 1) {
                value.score += 100 + 5 * (top100BucketSize - 1);
              }
              const finalistsBucketSize = this.effectiveBucketSize(value, value.finalists);
              if (finalistsBucketSize > 1) {
                value.score += 100 + finalistsBucketSize - 1;
              } else {
                value.score += this.effectiveBucketSize(value, value.nominees);
              }
              value.score += (value.totalScore / value.finalists.length);
              companyList.push(value);
            }
            break;
          case CompanyAlgorithm.SIGMOID_FUNCTION_MAPPING:
            if (value.finalists.length >= 2) {
              value.score = 0;
              value.creditMap = { };
              for (const room of value.rooms) {
                const rawCredit = this.companyCredit(room.docId);
                let adjustedCredit = rawCredit;
                if (this.entityMap.get(room.docId)?.unranked) {
                  adjustedCredit = 0.0;
                }
                value.creditMap[room.docId] = <CompanyCredit>{ raw: rawCredit, adjusted: adjustedCredit };
                value.score += adjustedCredit;
              }
              if (this.formGroup.value.onlineIrlPairWeight < 2) {
                const pair = this.getOnlineIrlPair(value);
                if (pair && value.creditMap[pair[0]] && value.creditMap[pair[1]]) {
                  const lesserPairMemberId = value.creditMap[pair[0]].adjusted < value.creditMap[pair[1]].adjusted ? pair[0] : pair[1];
                  const discount = (2 - this.formGroup.value.onlineIrlPairWeight) * value.creditMap[lesserPairMemberId].adjusted;
                  value.creditMap[lesserPairMemberId].adjusted -= discount;
                  value.score -= discount;
                }
              }
              companyList.push(value);
            }
            break;
        }
      });
      companyList.sort(this.compareByScore);
      rank = 0;
      const sv = <Matrix>ones(companyList.length);
      for (const company of companyList) {
        sv.subset(index(rank), company.score);
        company.rank = ++rank;
        company.winner = this.isWinnerByRank(company.rank, TerpecaCategory.TOP_COMPANY, this.yearLoaded);
      }
      const svNorm = <number>norm(sv, 'fro');
      for (const company of companyList) {
        company.normScore = company.score / svNorm;
      }
      this.roomDataSource = null;
      this.companyDataSource = new MatTableDataSource(companyList);
    }
    this.lastCategoryAnalyzed = this.formGroup.value.category;
    this.lastIrlApproach = this.formGroup.value.irlApproach;
    this.lastOnlineApproach = this.formGroup.value.onlineApproach;
    this.lastPValue = this.formGroup.value.p;
    this.lastVoteWeightingApproach = this.formGroup.value.voteWeightingApproach;
    this.lastDoLocalAdjustments = this.formGroup.value.doLocalAdjustments;
    this.lastCompanyAlgorithm = this.formGroup.value.companyAlgorithm;
    this.lastInflectionRank = this.formGroup.value.inflectionRank;
    this.lastTopOnlineEquivalentRank = this.formGroup.value.topOnlineEquivalentRank;
    this.lastTailReferenceCredit = this.formGroup.value.tailReferenceCredit;
  }

  weightedScore(entity: TerpecaRankedEntity) {
    return entity.strength;
  }

  newMetric() {
    return <RankingMetric>{
      pairsPredicted: 0,
      pairsViolated: 0
    };
  }

  percentFromMetric(metric: RankingMetric) {
    return metric.pairsPredicted / ((metric.pairsPredicted + metric.pairsViolated) || 1.0);
  }

  newEntity(room: TerpecaRoom, year: number): TerpecaRankedEntity {
    const entity = <TerpecaRankedEntity>{
      docId: room.docId,
      category: room.category,
      name: room.name,
      company: room.company,
      plays: 0,
      coverage: 0,
      allComps: 0,
      unranked: false,
      winner: false,
      resultsMap: { }
    };
    // If there are already results stored for this year, we record the original rank.
    if (room.resultsData && room.resultsData[year]) {
      const existingData = room.resultsData[year];
      if (existingData.originalRank) {
        entity.originalRank = existingData.originalRank;
      } else if (existingData.rank) {
        entity.originalRank = existingData.rank;
      }
    }
    return entity;
  }

  resetEntity(entity: TerpecaRankedEntity) {
    entity.plays = 0;
    entity.unrankedReasonEntries = [];
    entity.coverage = 0;
    entity.allComps = 0;
    entity.unranked = false;
    entity.winner = false;
    entity.companyCredit = 0;
    entity.resultsMap = { };
    this.entityMap.forEach((opponent: TerpecaRankedEntity, _key: string) => {
      if (opponent.docId !== entity.docId) {
        entity.resultsMap[opponent.docId] = <PairwiseComparison>{
          directWins: 0, directLosses: 0, directComps: 0, directWinFraction: 0.5, directConfidence: 0.5,
          directWinVoters: new Map<string, number>(), directLossVoters: new Map<string, number>(), directVoters: new Set<string>(),
          unweightedWins: 0, unweightedLosses: 0, unweightedComps: 0, unweightedWinFraction: 0.5, unweightedConfidence: 0.5,
          secondaryWins: 0, secondaryLosses: 0, secondaryComps: 0, secondaryPivots: 0, secondaryPivotsScaled: 0
        };
      }
    });
  }

  newCompany(companyName: string): RankedCompany {
    return <RankedCompany>{
      name: companyName,
      rooms: [],
      top25rooms: [],
      top50rooms: [],
      top100rooms: [],
      top200rooms: [],
      top10onlinerooms: [],
      top20onlinerooms: [],
      finalists: [],
      nominees: [],
      totalScore: 0,
      winner: false,
      creditMap: { }
    };
  }

  resetCompany(company: RankedCompany) {
    company.top25rooms = [];
    company.top50rooms = [];
    company.top100rooms = [];
    company.top200rooms = [];
    company.top10onlinerooms = [];
    company.top20onlinerooms = [];
    company.totalScore = 0;
    company.winner = false;
    company.creditMap = { };
  }

  registerPlay(entity: TerpecaRankedEntity) {
    entity.plays += 1;
  }

  registerComp(entity: TerpecaRankedEntity, opponentId: string, diff: number, scale: number, numRankings: number, userId: string) {
    entity.allComps += (1 / scale);
    const record = entity.resultsMap[opponentId];
    if (record.unweightedComps === 0) {
      entity.coverage += 1;
    }
    let weight = 1;
    switch (this.formGroup.value.voteWeightingApproach) {
      case VoteWeightingApproach.DEFAULT:
        break;
      case VoteWeightingApproach.DEFAULT_DIVIDED_BY_SQRT_N:
      case VoteWeightingApproach.DEFAULT_DIVIDED_BY_SQRT_N_REINFLATED:
        weight = 1 / <number>sqrt(numRankings);
        break;
    }
    if (diff > 0) {
      record.unweightedWins += (1 / scale);
      record.directWins += (weight / scale);
      record.directWinVoters.set(userId, weight / scale);
    } else {
      record.unweightedLosses += (1 / scale);
      record.directLosses += (weight / scale);
      record.directLossVoters.set(userId, weight / scale);
    }
    record.unweightedComps = record.unweightedWins + record.unweightedLosses;
    record.unweightedWinFraction = this.winFraction(record.unweightedWins, record.unweightedLosses);
    record.unweightedConfidence = this.confidence(record.unweightedWins, record.unweightedLosses);

    record.directComps = record.directWins + record.directLosses;
    record.directVoters.add(userId);
    record.directWinFraction = this.winFraction(record.directWins, record.directLosses);
    switch (this.formGroup.value.voteWeightingApproach) {
      case VoteWeightingApproach.DEFAULT:
      case VoteWeightingApproach.DEFAULT_DIVIDED_BY_SQRT_N:
        record.directConfidence = this.confidence(record.directWins, record.directLosses);
        break;
      case VoteWeightingApproach.DEFAULT_DIVIDED_BY_SQRT_N_REINFLATED: {
        const inflationRatio = (record.unweightedWins + record.unweightedLosses) / (record.directWins + record.directLosses);
        record.directConfidence = this.confidence(record.directWins * inflationRatio, record.directLosses * inflationRatio);
        break;
      }
    }
  }

  registerAbstain(entity: TerpecaRankedEntity, unrankedReasonEntry: UnrankedReasonEntry) {
    if (!entity.unrankedReasonEntries) {
      entity.unrankedReasonEntries = [unrankedReasonEntry];
    } else {
      entity.unrankedReasonEntries.push(unrankedReasonEntry);
    }
  }

  winFraction(wins: number, losses: number) {
    const n = wins + losses;
    if (n === 0) {
      return 0.5;
    }
    return wins / n;
  }

  // We use the Wilson score binomial confidence interval to calculate the confidence that one game is
  // better than another based on the head-to-head comparisons between those two games. The reason
  // we do this instead of just using win-loss percentages is so that we can give more weight when
  // there is more data.
  // https://en.wikipedia.org/wiki/Binomial_proportion_confidence_interval#Wilson_score_interval
  confidence(wins: number, losses: number) {
    const n = wins + losses;
    if (n === 0) {
      return 0.5;
    }
    const p = wins / n;
    const z = this.wilsonZValue();
    return (p + z * z / (2 * n)) / (1 + z * z / n);
  }

  // This just reverses the confidence calculation to get an estimated number of wins for a given
  // confidence and number of comps.
  winsNeededForConfidence(confidence: number, n: number) {
    if (n === 0) {
      return 0;
    }
    const z = this.wilsonZValue();
    const wins = n * (confidence * (1 + z * z / n) - (z * z / (2 * n)));
    if (wins < 0) {
      return 0;
    }
    if (wins > n) {
      return n;
    }
    return wins;
  }

  // The z-value here determines how quickly we converge on the actual win-loss percentage as the
  // number of comparisons increases.
  // (Note that setting z to 0 is equivalent to just using the win-loss percentages directly.)
  wilsonZValue() {
    let z: number;
    switch (this.formGroup.value.p) {
      case 0.01:
        z = 2.575829306;
        break;
      case 0.05:
        z = 1.959963986;
        break;
      case 0.1:
        z = 1.644853625;
        break;
      case 0.5:
        z = 0.674;  // We use less precision here to precisely match what was used in 2018.
        break;
      case 1:
        z = 0;
        break;
      default:
        z = -1;
        break;
    }
    return z;
  }

  compareByScore(a: (TerpecaRankedEntity | RankedCompany), b: (TerpecaRankedEntity | RankedCompany)) {
    return b.score - a.score;
  }

  compareByRawCredit(creditMap: CreditMap) {
    return (a: TerpecaRoom, b: TerpecaRoom) => {
      const aCredit = creditMap[a.docId]?.raw || 0;
      const bCredit = creditMap[b.docId]?.raw || 0;
      if (aCredit !== bCredit) {
        return bCredit - aCredit;
      }
      return compareEntitiesByName(a, b);
    };
  }

  compareByCategoryAndRank(entityMap: Map<string, TerpecaRankedEntity>) {
    return (a: TerpecaRoom, b: TerpecaRoom) => {
      if (a.category !== b.category) {
        return a.category - b.category;
      }
      const aRank = entityMap.has(a.docId) ? entityMap.get(a.docId).rank : 1000000;
      const bRank = entityMap.has(b.docId) ? entityMap.get(b.docId).rank : 1000000;
      if (aRank !== bRank) {
        return aRank - bRank;
      }
      return compareEntitiesByName(a, b);
    };
  }

  doLocalAdjustments(entityList: TerpecaRankedEntity[]) {
    for (let i = 0; i < entityList.length - 1; ++i) {
      for (let j = 0; j < entityList.length - i - 1; ++j) {
        if (entityList[j].resultsMap[entityList[j + 1].docId].unweightedConfidence < 0.5) {
          this.swapEntities(entityList, j, j + 1);
        }
      }
    }
  }

  swapEntities(entityList: TerpecaRankedEntity[], pos1: number, pos2: number) {
    const tempEntity = entityList[pos1];
    entityList[pos1] = entityList[pos2];
    entityList[pos2] = tempEntity;
    // We want to keep the scores intact with the positions to maintain the sort order.
    const tempScore = entityList[pos1].score;
    entityList[pos1].score = entityList[pos2].score;
    entityList[pos2].score = tempScore;
  }

  containsVersion(rooms: (TerpecaRoom | TerpecaRankedEntity)[], versionName: string) {
    if (versionName) {
      return rooms.filter(r => this.getVersionName(r) === versionName).length > 0;
    }
    return false;
  }

  isDeepInside(room: (TerpecaRoom | TerpecaRankedEntity)) {
    return room.company === 'Deep Inside' && (room.name.includes('Le Palais') || room.name.includes('Le Magicien'));
  }

  isDeepInsideCombined(room: (TerpecaRoom | TerpecaRankedEntity)) {
    return room.company === 'Deep Inside' && room.name.startsWith('Full Experience');
  }

  isDoorsOfDivergenceHeresy(room: (TerpecaRoom | TerpecaRankedEntity)) {
    return room.company === 'Doors of Divergence' && room.name.includes('Heresy');
  }

  isDoorsOfDivergenceMadness(room: (TerpecaRoom | TerpecaRankedEntity)) {
    return room.company === 'Doors of Divergence' && room.name.includes('Madness');
  }

  isLaMina(room: (TerpecaRoom | TerpecaRankedEntity)) {
    return room.company.includes('Unreal Room Escape') && room.name.includes('La mina');
  }

  isPetra(room: (TerpecaRoom | TerpecaRankedEntity)) {
    return room.company === 'Petra Escape Room' && room.name.includes('Petra');
  }

  isSanatorium(room: (TerpecaRoom | TerpecaRankedEntity)) {
    return room.company === 'Lockhill' && room.name.includes('Το Σανατόριο');
  }

  getVersionName(room: (TerpecaRoom | TerpecaRankedEntity)): string {
    if (room.category === TerpecaCategory.TOP_ROOM) {
      // We treat La Mina as SEPARATE in every case except for the company award in 2020, where we treat it as BEST.
      // This weird case requires that we reload the data if we change the category while analyzing that year.
      if (this.formGroup.value.year === 2020 && this.formGroup.value.category === TerpecaCategory.TOP_COMPANY && this.isLaMina(room)) {
        return this.LA_MINA_ID;
      }
      if (this.formGroup.value.deepInsideApproach !== RoomVersionApproach.SEPARATE && this.isDeepInside(room)) {
        return this.DEEP_INSIDE_ID;
      }
      if (this.formGroup.value.doorsOfDivergenceApproach !== RoomVersionApproach.SEPARATE) {
        if (this.isDoorsOfDivergenceHeresy(room)) {
          return this.DOORS_OF_DIVERGENCE_HERESY_ID;
        }
        if (this.isDoorsOfDivergenceMadness(room)) {
          return this.DOORS_OF_DIVERGENCE_MADNESS_ID;
        }
      }
      if (this.formGroup.value.petraApproach !== RoomVersionApproach.SEPARATE && this.isPetra(room)) {
        return this.PETRA_ID;
      }
      if (this.formGroup.value.sanatoriumApproach !== RoomVersionApproach.SEPARATE && this.isSanatorium(room)) {
        return this.SANATORIUM_ID;
      }
    }
    return null;
  }

  getCompositeId(room: (TerpecaRoom | TerpecaRankedEntity)): string {
    if (room.category === TerpecaCategory.TOP_ROOM) {
      if (this.formGroup.value.deepInsideApproach === RoomVersionApproach.COMBINED && this.isDeepInside(room)) {
        return this.getVersionName(room);
      }
      if (this.formGroup.value.doorsOfDivergenceApproach === RoomVersionApproach.COMBINED && (
        this.isDoorsOfDivergenceHeresy(room) || this.isDoorsOfDivergenceMadness(room))) {
        return this.getVersionName(room);
      }
      if (this.formGroup.value.petraApproach === RoomVersionApproach.COMBINED && this.isPetra(room)) {
        return this.getVersionName(room);
      }
      if (this.formGroup.value.sanatoriumApproach === RoomVersionApproach.COMBINED && this.isSanatorium(room)) {
        return this.getVersionName(room);
      }
    }
    return room.docId;
  }

  updateCompositeRoom(room: TerpecaRoom) {
    const compositeId = this.getCompositeId(room);
    if (compositeId !== room.docId) {
      room.docId = compositeId;
      switch (compositeId) {
        case this.DEEP_INSIDE_ID:
          room.name = 'Le Magicien de Paris & Le Palais de l’horreur';
          room.englishName = 'The Magician of Paris & The Funhouse (all versions)';
          room.horrorLevel = HorrorLevel.ACTIVE;
          break;
        case this.DOORS_OF_DIVERGENCE_HERESY_ID:
          room.name = 'Heresy: 1897';
          room.horrorLevel = HorrorLevel.PASSIVE;
          break;
        case this.DOORS_OF_DIVERGENCE_MADNESS_ID:
          room.name = 'Madness: 1917';
          room.horrorLevel = HorrorLevel.PASSIVE;
          break;
        case this.PETRA_ID:
          room.name = 'Petra - El reino perdido';
          room.englishName = 'Petra - The Lost Kingdom (all versions)';
          room.horrorLevel = HorrorLevel.SPOOKY;
          break;
        case this.SANATORIUM_ID:
          room.name = 'Το Σανατόριο';
          room.englishName = 'The Sanatorium (all versions)';
          room.horrorLevel = HorrorLevel.ACTIVE;
          break;
      }
    }
  }

  getOnlineIrlPair(company: RankedCompany): string[] {
    const pair = [];
    switch (company.name) {
      case '60out Escape Rooms':
        for (const room of company.rooms) {
          if (room.name.includes('Miss Jezebel')) {
            pair.push(room.docId);
          }
        }
        break;
      case 'Break The Brain':
        for (const room of company.rooms) {
          if (room.name.includes('Freakshow')) {
            pair.push(room.docId);
          }
        }
        break;
      case 'Escapologic':
        for (const room of company.rooms) {
          if (room.name.includes('Immaterium')) {
            pair.push(room.docId);
          }
        }
        break;
      case 'Exit19.pl':
        for (const room of company.rooms) {
          if (room.name.includes('Wehikuł Czasu')) {
            pair.push(room.docId);
          }
        }
        break;
      case 'Hourglass Escapes':
        for (const room of company.rooms) {
          if (room.name.includes('Evil Dead 2')) {
            pair.push(room.docId);
          }
        }
        break;
      case 'Immersia Escape Games Canada':
        for (const room of company.rooms) {
          if (room.name.includes('Le Grand Immersia Hotel')) {
            pair.push(room.docId);
          }
        }
        break;
      case 'Improbable Escapes':
        for (const room of company.rooms) {
          if (room.name.includes('The Triwizard Trials')) {
            pair.push(room.docId);
          }
        }
        break;
      case 'Locurio':
        for (const room of company.rooms) {
          if (room.name.includes('The Vanishing Act')) {
            pair.push(room.docId);
          }
        }
        break;
      case 'Logic Locks':
        for (const room of company.rooms) {
          if (room.name.includes('The Amsterdam Catacombs')) {
            pair.push(room.docId);
          }
        }
        break;
      case 'NorCal Escape Co.':
        for (const room of company.rooms) {
          if (room.name.includes('Condemned 2')) {
            pair.push(room.docId);
          }
        }
        break;
      case 'The Escape Game':
        for (const room of company.rooms) {
          if (room.name.includes('Ruins: Forbidden Treasure')) {
            pair.push(room.docId);
          }
        }
        break;
      default:
        return null;
    }
    if (pair.length !== 2) {
      console.log(`Failed to load IRL/online pair for ${company.name} - got ${pair}`);
      return null;
    }
    return pair;
  }

  companyCredit(roomId: string) {
    if (this.entityMap.has(roomId)) {
      // This is a finalist!
      const room: TerpecaRankedEntity = this.entityMap.get(roomId);
      return this.creditForRankedRoom(room.score, room.category);
    }
    if (this.roomMap.has(roomId)) {
      // This is a nominee!
      const room: TerpecaRoom = this.roomMap.get(roomId);
      const nominationCount = getNominationCount(room, this.yearLoaded);
      const nominationCountThreshold = this.settings.finalistThreshold(this.yearLoaded);
      if (nominationCount === nominationCountThreshold - 1) {
        // We're only giving company credit to rooms that were one nomination short of the threshold, and both IRL and online
        // rooms are give the same credit here, equal to one half the credit given to the lowest finalist in either category.
        if (this.bottomOnlineRoomScore > 0) {
          return min(this.creditForRankedRoom(this.bottomRoomScore, TerpecaCategory.TOP_ROOM),
                     this.creditForRankedRoom(this.bottomOnlineRoomScore, TerpecaCategory.TOP_ONLINE_ROOM)) * 0.5;
        }
        return this.creditForRankedRoom(this.bottomRoomScore, TerpecaCategory.TOP_ROOM) * 0.5;
      }
    }
    return 0;
  }

  creditForRankedRoom(score: number, category: TerpecaCategory) {
    if (category === TerpecaCategory.TOP_ROOM) {
      // This calculation is based on using the sigmoid function f(g(x)) = 0.5*(1 - g(x)/(1 + |g(x)|)),
      // where g(x) = ax + b, determined using f(g(score(inflectionRank))) = 0.5 and f(g(score(2*inflectionRank))) = tailReferenceCredit.
      const multiplier = (0.5 - this.formGroup.value.tailReferenceCredit) / this.formGroup.value.tailReferenceCredit;
      const gX = multiplier * (this.inflectionRoomScore - score) / (this.inflectionRoomScore - this.tailReferenceRoomScore);
      return 0.5 * (1 - gX / (1 + abs(gX)));
    }
    if (category === TerpecaCategory.TOP_ONLINE_ROOM) {
      // This uses the same graph shape that we generated for the online rooms, but does a linear range mapping
      // using the #1 online and IRL rooms at one end, and the equivalent inflection point ranks at the other.
      // Then it scales the result using the given online equivalent score.
      const remappedScore = this.inflectionRoomScore + (this.topRoomScore - this.inflectionRoomScore) *
          (score - this.inflectionOnlineRoomScore) / (this.topOnlineRoomScore - this.inflectionOnlineRoomScore);
      return this.creditForRankedRoom(remappedScore, TerpecaCategory.TOP_ROOM) *
        this.creditForRankedRoom(this.topOnlineEquivalentScore, TerpecaCategory.TOP_ROOM) /
        this.creditForRankedRoom(this.topRoomScore, TerpecaCategory.TOP_ROOM);
    }
    return 0.0;
  }

  effectiveBucketSize(company: RankedCompany, bucket: string[]) {
    let bucketSize = bucket.length;
    if (this.formGroup.value.onlineIrlPairWeight !== 2) {
      const pair = this.getOnlineIrlPair(company);
      if (pair !== null && bucket.includes(pair[0]) && bucket.includes(pair[1])) {
        bucketSize -= (2 - this.formGroup.value.onlineIrlPairWeight);
      }
    }
    return bucketSize;
  }

  allYears() {
    return this.settings.allYears.filter(key => this.canShowRoomTitles(this.auth.currentUser, key));
  }

  allCategories(year: number) {
    if (this.hadOnlineRoomVoting(year)) {
      return [TerpecaCategory.TOP_ROOM, TerpecaCategory.TOP_ONLINE_ROOM, TerpecaCategory.TOP_COMPANY];
    }
    return [TerpecaCategory.TOP_ROOM, TerpecaCategory.TOP_COMPANY];
  }

  categoryName(category: TerpecaCategory) {
    switch (category) {
      case TerpecaCategory.TOP_ROOM:
        return 'In-Person Rooms';
      case TerpecaCategory.TOP_ONLINE_ROOM:
        return 'Online Rooms';
      case TerpecaCategory.TOP_COMPANY:
        return 'Companies';
    }
  }

  approachName(approach: ResultsApproach, category: TerpecaCategory) {
    switch (approach) {
      case ResultsApproach.DEFAULT:
        return 'no secondary comps (2018)';
      case ResultsApproach.DEFAULT_PLUS_SECONDARY_SQRT_V1:
        return 'secondary comps v1 (Σ/√n) (2019)';
      case ResultsApproach.DEFAULT_PLUS_SECONDARY_SQRT_V2:
        return 'secondary comps v2 weighted (Σ/√n)' + (category === TerpecaCategory.TOP_ONLINE_ROOM ? ' (2020-2021)' : ' (2020)');
      case ResultsApproach.DEFAULT_PLUS_SECONDARY_FULL_V2:
        return 'secondary comps v2 weighted (Σ)' + (category === TerpecaCategory.TOP_ROOM ? ' (2021)' : '');
      case ResultsApproach.DEFAULT_PLUS_SECONDARY_FULL_V3:
        return 'secondary comps v3 weighted (Σ) (2022-2023)';
    }
  }

  // TODO: This list should probably be generated from the data, but would need to require some minimum number of users/rooms
  // from a given country to maintain privacy for users from underrepresented regions.
  allCountryFilters() {
    return ['',
            'Australia',
            '-Australia',
            'Belgium',
            '-Belgium',
            'Canada',
            '-Canada',
            'France',
            '-France',
            'Germany',
            '-Germany',
            'Greece',
            '-Greece',
            'Israel',
            '-Israel',
            'Netherlands',
            '-Netherlands',
            'Poland',
            '-Poland',
            'Spain',
            '-Spain',
            'United Kingdom',
            '-United Kingdom',
            'United States',
            '-United States'];
  }

  countryFilterLabel(country: string) {
    if (country) {
      if (country.startsWith('-')) {
        return `not ${country.substring(1)}`;
      }
      return country;
    }
    return 'all countries';
  }

  matchesCountryFilter(filter: string, country: string) {
    if (!filter) {
      return true;
    }
    if (filter.startsWith('-')) {
      return country && country !== filter.substring(1);
    }
    return country === filter;
  }

  matchesHorrorLevelFilter(filter: HorrorLevel[], horrorLevel?: HorrorLevel) {
    return filter?.length === allHorrorLevels.length || filter?.includes(horrorLevel);
  }

  matchesHorrorPreferenceFilter(filter: HorrorPreference[], horrorPreference?: HorrorPreference) {
    return filter?.length === allHorrorPreferences.length || filter?.includes(horrorPreference);
  }

  pValueName(pVal: number) {
    const label = `p = ${pVal}`;
    switch (pVal) {
      case 0.5:
        return `${label} (2018)`;
      case 0.05:
        return `${label} (2019-2023)`;
    }
    return label;
  }

  voteWeightingApproachName(approach: VoteWeightingApproach) {
    switch (approach) {
      case VoteWeightingApproach.DEFAULT:
        return '1 (2018-2021)';
      case VoteWeightingApproach.DEFAULT_DIVIDED_BY_SQRT_N:
        return '1/√n (2022)';
      case VoteWeightingApproach.DEFAULT_DIVIDED_BY_SQRT_N_REINFLATED:
        return '1/√n reinflated (2023)';
    }
  }

  roomVersionApproachName(approach: RoomVersionApproach) {
    switch (approach) {
      case RoomVersionApproach.SEPARATE:
        return 'separate';
      case RoomVersionApproach.BEST:
        return 'best only';
      case RoomVersionApproach.COMBINED:
        return 'combined';
    }
  }

  companyVersionApproachName(approach: CompanyVersionApproach) {
    switch (approach) {
      case CompanyVersionApproach.DEFAULT:
        return 'default';
      case CompanyVersionApproach.IGNORE_COMBINED_GAMES:
        return 'ignore combined';
    }
  }

  companyAlgorithmName(algorithm: CompanyAlgorithm) {
    switch (algorithm) {
      case CompanyAlgorithm.DISCRETE_BUCKETS:
        return 'discrete buckets (2019-2020)';
      case CompanyAlgorithm.SIGMOID_FUNCTION_MAPPING:
        return 'sigmoid function (2021)';
    }
  }

  getCompanyNames(room: TerpecaRoom, year: number) {
    if (room.country === 'Andorra') {
      if (room.company.includes('Maximum Escape')) {
        return [(year < 2021) ? 'Claustrophobia' : 'Maximum Escape'];
      }
    } else if (room.country === 'France') {
      if (this.formGroup.value.deepInsideApproach === CompanyVersionApproach.IGNORE_COMBINED_GAMES && this.isDeepInsideCombined(room)) {
        return [];
      }
    } else if (room.country === 'Israel') {
      if (room.company.includes('formerly Escape Challenge') || room.company.includes('formerly BrainIT')) {
        return ['Escape Room Israel'];
      }
    } else if (room.country === 'Netherlands') {
      if (room.company.includes('formerly Escape Room Nederland')) {
        return ['Mama Bazooka'];
      }
      if (year < 2023) {
        if (room.company.includes('formerly The Escape Room Rijswijk')) {
          return ['The Escape Room Rijswijk'];
        }
      }
    } else if (room.country === 'Spain') {
      if (room.company.includes('Mad Mansion')) {
        return ['Mad Mansion'];
      }
      if (year >= 2021) {
        if (year < 2023) {
          if (room.company.includes('Inmortal Room')) {
            return ['Inmortal Room'];
          }
          if (room.company.includes('Clue Hunter')) {
            return ['Clue Hunter'];
          }
          if (room.company.includes('Escapem')) {
            return ['Escapem'];
          }
        }
        if (room.company.includes('Experiencity') && room.company.includes('Elements Escape Rooms')) {
          return ['Elements Escape Rooms', 'Experiencity'];
        }
        if (room.company.includes('Experiencity')) {
          return ['Experiencity'];
        }
        if (room.company.includes('Elements Escape Rooms')) {
          return ['Elements Escape Rooms'];
        }
      }
      if (room.company.includes('Metamorfosis')) {
        return [(year < 2023) ? 'Unreal Room Escape' : 'Metamorfosis Escape Room'];
      }
      if (room.company.includes('Mina Madrid')) {
        return [(year < 2021) ? 'Unreal Room Escape' : 'Mina Madrid'];
      }
    } else if (room.country === 'United Kingdom') {
      if (room.company.includes('formerly Clockwork Dog')) {
        return ['The Panic Room'];
      }
      if (room.company.includes('Pier Pressure (formerly Pressure Point Escape Rooms')) {
        return (year < 2021) ? ['Pier Pressure', 'Pressure Point Escape Rooms'] : ['Pier Pressure'];
      }
    } else if (room.country === 'United States') {
      if (room.company.includes('formerly ESCapades LA')) {
        return (year < 2020) ? ['Level Games', 'ESCapades LA'] : ['Level Games'];
      }
      if (room.company.includes('Ravenchase Adventures')) {
        return ['Ravenchase Adventures'];
      }
    }
    return room.company.split(' / ');
  }
}
